<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header-smalltop">
      <parallax class="page-header-image"
                style="background-image: url('/img/back-to-school-start-MACVKugZ8WQ.jpg')">
      </parallax>
      <notifications></notifications>
    </div>
    <div v-if="newsList.length>0" class="">
      <div class="features-3">
        <div class="container">
          <h2>Notes and Happenings</h2>
          <div class="row">
            <div class="col-md-3" v-for="(news,index) in newsList" :key="index">

              <template v-if="news.category==='NEWS'">
                <div class="info info-hover">
                  <div v-if="news.title.substring(0,1)!='-'">
                    <div v-if="news.updatedAt >= $root.portal.lastLogon" class="icon icon-primary icon-circle">
                      <i class="now-ui-icons tech_watch-time"></i>
                    </div>
                    <div v-else class="icon icon-info icon-circle">
                      <i class="now-ui-icons objects_globe"></i>
                    </div>
                    <h4 class="info-title">
                      <b-badge v-if="news.updatedAt >= $root.portal.lastLogon" class="pull-left" style="position:relative;top:-15px;" pill variant="warning">New</b-badge>
                      {{news.title}}
                    </h4>
                  </div>
                  <!-- <p class="description">{{news.description}}</p> -->
                  <div class="description" v-html="news.description" ></div>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator-line separator-primary"></div>
    <div class="projects-5" v-if="$root.isUserAuthenticated()">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Your Statistics</h2>
            <h4 class="description">Here are a few account stats you may find helpful.</h4>
            <div class="section-space"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 ml-auto">
            <card type="background" raised class="card-background-product" style="background-image: url('/img/project8.jpg')">
              <h2 class="card-title">Logon Statistics</h2>
              <p class="card-description">
                These stats help keep track of where and when your account was used. You can also get a listing
                of your latest logins.
              </p>
              <div class="card-footer text-center" style="margin-left:1em;margin-right:1em;">
                <a @click="toggleShowAccessLog" class="btn btn-primary btn-round btn-lg btn-block">Show Access Log</a>
              </div>
            </card>
          </div>
          <div class="col-md-5 mr-auto">
            <info-section type="danger" icon="now-ui-icons design-2_ruler-pencil" title="Logon Count" :description="$root.portal.logonCount.toString()" />
            <info-section type="danger" icon="now-ui-icons ui-2_chat-round" title="Last IP Address" :description=" $root.portal.lastIP" />
            <info-section type="danger" icon="now-ui-icons business_chart-pie-36" title="Last Logon Date" :description="moment($root.portal.lastLogon).format( 'DD-MMM-YY hh:mm:SS A')" />
          </div>
        </div>
        <div class="row">
          <div v-if="showAccessLog" class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Access Log</h2>
            <el-table  :data="$root.account.remoteAccessLog">
              <el-table-column width="60" min-width="60" type="index" sortable></el-table-column>
              <el-table-column min-width="200" prop="who" label="Who" sortable></el-table-column>
              <el-table-column min-width="150" prop="where" label="Where" sortable></el-table-column>
              <el-table-column min-width="200" prop="When" label="When" sortable  >
                <template slot-scope="props" width="200" min-width="200" :default-sort="{ prop: 'when', order: 'descending'}">
                  {{ moment( props.row.when ).format("ddd, MMM Do, hh:mma") }}
                </template>
              </el-table-column>
              <el-table-column min-width="200" prop="notes" label="Description"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    Badge,
    Button,
    Card,
    FormGroupInput,
    InfoSection,
    TabPane,
    Tabs
  } from '@/components';
  import GeneralService from "@/services/GeneralService";
  import {
    Table,
    TableColumn
  } from 'element-ui';
  import Moment from "moment";

  export default {
    name: 'app-main-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      Badge,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        showAccessLog: false,
        newsList: [],
        students: []
      }
    },
    async mounted() {
      await Promise.all([
        this.getNews(),
        this.getStudents()
      ]);

      if (this.students.length === 0) {
        this.$router.push("/app/profile/#addStudents");
      }
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      toggleShowAccessLog() {
        this.showAccessLog = !this.showAccessLog;
      },
      async getStudents() {
        let t = this;

        try {
          let response = await GeneralService.getStudents();
          this.students = response.data.students;

        } catch (error) {
          // todo: tell the user about this issue...
          console.error(error);
        }
      },
      async getNews() {
        let t = this;

        try {
          let response = await GeneralService.getNews();
          t.newsList = response.data;
        } catch (error) {
          t.newsList = [];
        }
      }
    }
  }
</script>
<style>
</style>
