/*

    Shift4 Interface V1.01
    (c) 2019 mySchool.cafe, All Rights Reserved
    
    Developed By: Wolf Scott

 */

"use strict";

let axios = require("axios");

export default {
  
  async tokenizeCard(token) {
    
    if(token.i4go_response !== "SUCCESS") {   // quick check.
      return {data: {}};
    }
    
    let Shift4;
    let cardParams = {
      fuseaction          : "api.jsonPostCardEntry",
      i4go_accessblock    : token.i4go_accessblock,
      i4go_cardnumber     : token.newCC.card,
      i4go_expirationmonth: token.newCC.expDate.substring(0, 2),
      i4go_expirationyear : token.newCC.expDate.substring(2, 4),
      i4go_cvv2code       : token.newCC.cvv,
      i4go_ccv2indicator  : "1",      // cvv present
      i4go_cardholdername : `${token.ccInfo.firstName} ${token.ccInfo.lastName}`,
      i4go_postalcode     : token.ccInfo.zipCode,
      i4go_streetaddress  : token.ccInfo.address1,
      mySchool            : "2zqeEcrTzEo938"
    };
    
    let defaultHeaders            = axios.defaults.headers.common; //
    axios.defaults.headers.common = {};
    Shift4                        = axios.create({
                                                   baseURL     : token.i4go_server,
                                                   responseType: 'json',
                                                   params      : cardParams,
                                                   headers     : {
                                                     'Content-Type': 'application/json',
                                                     'Accept'      : 'application/json'
                                                   },
                                                 });
    
    //console.log("our cardparms", cardParams);
    
    const response                = await Shift4.get('/');
    axios.defaults.headers.common = defaultHeaders;
    return response;
    
  }
}
