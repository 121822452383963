<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header-smalltop">
      <parallax class="page-header-image"
                style="background-image: url('/img/back-to-school-start-MACVKugZ8WQ.jpg')">
      </parallax>
     
    </div>
    <div class="section">
      <div class="row">
        <div class="card col-md-8 ml-auto mr-auto" style="border-radius:1em;">
          <h2 class="title">Documents</h2>
          <div style="padding-left:20px;">
            <a href="/doc/ingredients-stlukes.pdf" target="_blank">
              <i class="fa fa-file-pdf" style="font-size:1.5em;color:red;padding-right:1em;"></i>
              <span style="font-weight:800;">Nutrient Listing (PDF) </span>
            </a>
            <p style="padding-left:40px;font-size:.9em;font-weight:500;">Master Nutrient Listing.</p>
          </div>
          <div style="padding-left:20px;">
            <a href="/video/paymentDetails.mp4" target="_blank">
              <i class="fa fa-file-video-o" style="font-size:1.5em;color:red;padding-right:1em;"></i>
              <span style="font-weight:800;">Payment Registration Overview (MP4) </span>
            </a>
            <p style="padding-left:40px;font-size:.9em;font-weight:500;">How to connect your credit card to your account.</p>
          </div>
          <div style="padding-left:20px;">
            <a href="/video/LinkingStudent-Lindy.mp4" target="_blank">
              <i class="fa fa-file-video-o" style="font-size:1.5em;color:red;padding-right:1em;"></i>
              <span style="font-weight:800;">Register/Link a student (MP4) </span>
            </a>
            <p style="padding-left:40px;font-size:.9em;font-weight:500;">How to link a student to your account.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card col-md-8 ml-auto mr-auto" style="border-radius:1em;padding-bottom:2em;">
          <h2 class="title">FAQ</h2>
            <faq-card v-for="(faq,index) in faqs"
              :faq="faq" 
              :index="index" 
              :key="index" 
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Button, Card, FormGroupInput, InfoSection, TabPane, Tabs, FaqCard } from '@/components';
  import GeneralService from "@/services/GeneralService";

  export default {
    name      : 'app-about-page',
    bodyClass : 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      FaqCard,
      InfoSection,
      [ Button.name ]        : Button,
      [ FormGroupInput.name ]: FormGroupInput
    },
    computed: {
    },
    data() {
      return {
        faqs: []
      }
    },
    async mounted() {
      this.getFaqs();
    },
    methods   : {
      colId( id ) {
        return "col" + id.toString();
      },
      async getFaqs() {
        let t = this;

        let response = await GeneralService.getFaqs();
        t.faqs       = response.data;
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
