<template>
   <div class="wrapper">
      <div class="page-header page-header-mini">
         <parallax class="page-header-image"
                   style="background-image: url('/img/pexels-photo-716431.jpeg')">
         </parallax>
         <div class="content-center">
            <div class="row">
               <div class="col-md-8 ml-auto mr-auto">
                  <h1 class="title">License And Usage Stuff</h1>
                  <h4>Some things to ponder for the curious minds...</h4>
               </div>
            </div>
         </div>
      </div>
      <div class="section">
         <div class="about-office">
            <div class="container">
               <div class="row text-center">
                  <div class="col-md-8 ml-auto mr-auto">
                     <h2 class="title">License Agreement</h2>
                     <h4 class="description">Some technobabble goes here...</h4>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </div>
</template>
<script>
  import { Badge, Button, Card, Checkbox, FormGroupInput, InfoSection, SFormInput } from '@/components';
  import { Option, Select } from 'element-ui'

  export default {
    name      : 'about-us',
    bodyClass : 'about-us',
    components: {
      Card,
      InfoSection,
      Badge,
      SFormInput,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput,
      [ Select.name ]        : Select,
      [ Option.name ]        : Option,
    },
    data() {
      return {
        form: {
          firstName: '',
          email    : '',
          body     : ''
        }
      }
    }
  }
</script>
<style>
</style>
