<template>
  <navbar position="fixed" :transparent="transparent" :color-on-scroll="colorOnScroll" menu-classes="ml-auto" >
    <template slot-scope="{toggle, isToggled}">
      <router-link v-popover:popover class="navbar-brand" to="/app/main">
        <img height="50px;" src="/img/myschoolCafe3.png">
      </router-link>
      <el-popover ref="popover1" popper-class="popover" placement="bottom" width="200" trigger="hover">
        <div class="popover-body">AppHome</div>
      </el-popover>
    </template>
    <template slot="navbar-menu">

      <li class="nav-item">
        <router-link class="nav-link" to="/app/main">
          <p>Home</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" to="/app/meals">
          <p>Meals</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/app/history">
          <p>History</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/app/profile">
          <p>My Profile</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/app/help">
          <p>Help</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-if="!$root.isUserAuthenticated()" style="border-radius:3em;" class="nav-link btn btn-primary" to="/login">
          <p>Parent Login</p>
        </router-link>
        <a v-else class="nav-link" @click="logout()">
          <p>Log Out, {{$root.alias()}}</p>
        </a>

      </li>

    </template>
  </navbar>
</template>

<script>
  import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'

  export default {
    name      : 'app-navbar',
    props     : {
      transparent  : Boolean,
      colorOnScroll: Number,
    },
    components: {
      DropDown,
      Navbar,
      NavbarToggleButton,
      NavLink,
      [Popover.name]: Popover
    },
    methods   : {
      logout() {
        let t = this;
        t.$root.logout();
      }
    }
  }
</script>

<style scoped>

</style>
