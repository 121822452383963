<template>


   <b-form-group horizontal label-cols-lg="3"  label-class="text-sm-right" :label="label" :label-for="id">
      <b-form-input v-if="type!=='date'"
                    class="form-control"
                    :id="id"
                    :ref="iRef"
                    :type="type"
                    :value="value"
                    :style="cstyle"
                    @input.native="$emit('input', $event.target.value)"
                    :readonly="readonly"
                    v-bind="$attrs"
                    :required="required"
                    :placeholder="placeholder"
      ></b-form-input>
      <b-form-input v-if="type==='date'"
                    class="form-control"
                    :id="id"
                    :ref="iRef"
                    :type="type"
                    :value="dateToYYYYMMDD(value)"
                    :style="cstyle"
                    @input.native="$emit('input', $event.target.value)"
                    :readonly="readonly"
                    v-bind="$attrs"
                    :required="required"
                    :placeholder="placeholder"
      ></b-form-input>
   </b-form-group>

   <!--    <input-->
   <!--      :value="value"-->
   <!--      @input="$emit('input',$event.target.value)"-->
   <!--      v-bind="$attrs"-->
   <!--      class="form-control"-->
   <!--      aria-describedby="addon-right addon-left">-->

</template>
<script>

  import Moment from "moment";
  // TYPES = ['text', 'password', 'email', 'number', 'url', 'tel', 'search', 'range', 'color', 'date', 'time', 'datetime', 'datetime-local', 'month', 'week']; // @vue/component
  export default {
    inheritAttrs: false,
    name        : 'sf-input',
    props       : {
      id         : {type: String, default: () => { "input-"+Math.random().toString()}},
      iRef       : {type: String, default: null},
      placeholder: {type: String, default: ""},
      readonly   : {type: Boolean, default: false},
      required   : {type: Boolean, default: false},
      type       : {type: String, default: "text"},
      label      : {type: String, default: ""},
      cstyle     : {type: String, default: ""},
      value      : {type: [ String, Number, Date ], default: ""}
    },
    components  : {},
    computed    : {
      // hasIcon() {
      //   const {addonRight, addonLeft} = this.$slots
      //   return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
      // }
    },
    mounted() {

    },
    data() {
      return {
        mData: ""
      }
    },
    methods     : {
      dateToYYYYMMDD(d) {
        // may have timezone caveats https://stackoverflow.com/a/29774197/1850609
        // return date && date.toISOString().split('T')[0]
        // return Moment( d , this.$root.user.TZ).format('YYYY-MM-DD');
        return Moment(d).format('YYYY-MM-DD');
      },
      updateValue: function(target) {
        this.$emit('input', target.valueAsDate);
      },
      selectAll  : function(event) {
        // Workaround for Safari bug
        // https://stackoverflow.com/q/1269722/1850609
        setTimeout(function() {
          event.target.select()
        }, 0)
      }
    }
  }
</script>
<style>

</style>
