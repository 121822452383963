<template>
  <div>
    <div class="page-header page-header-smalltop non-printable">
      <parallax class="page-header-image"
                style="background-image: url('/img/back-to-school-start-MACVKugZ8WQ.jpg')">
      </parallax>
      <notifications class="non-printable"></notifications>
    </div>
    <b-container>
      <card class="transaction-card ml-auto mr-auto shadow mt-4" style="border-radius:1em;">
        <h3 style="margin-bottom:0;">Transaction Listing  <span style="float:right;">Account Balance: {{currency(accountBalance)}}</span></h3><b-button class="btn btn-sm non-printable" style="display:inline-flex;float:right;margin-left:10px;margin-right:10px;border-radius:.5em;font-size:.8em;" @click="printTransactions">PRINT</b-button>
        <p class="non-printable" style="display:inline-flex;padding:0;margin:0;font-style:italic;font-size:.9em;">The last 200 transactions are available for review.  Click on a transaction to view the receipt, if available.</p>
        <b-table striped hover responsive class="table-format" :items="transactions" :fields="fields" @row-clicked="showReceipt">
          <template v-slot:cell(transactionDate)="data">
            {{moment(data.value).format("dddd, LL")}}
          </template>
          <template v-slot:cell(isPayment)="data">
            {{(data.value === true) ? "Payment" : "Charge" }}
          </template>
          <template v-slot:cell(tenderAmount)="data">
            <span :style="((!data.item.isPayment && data.item.tenderAmount<0 ) || (data.item.isPayment && data.item.tenderAmount>0)) ? 'color:green;' : 'color:red;'">{{data.value}}</span>
          </template>
        </b-table>
      </card>

      <b-modal id="ticket-receipt" ok-only hide-header-close>
        <img  src="/img/myschoolCafe3.png"/>
        <hr />
        <div v-if="receipts && receipts.length>0" class="receipt-format" :style="(isPayment) ? 'padding-left:0' : 'padding-left:40px;'" v-for="(receipt,receiptIndex) in receipts" :key="receiptIndex">{{html2Text(receipt) || ""}}</div>
        <div v-else>No Receipt Information</div>
      </b-modal>

    </b-container>
  </div>
</template>
<script>

  import { Button, Card, FormGroupInput, TabPane, Tabs } from '@/components';

  import GeneralService from "@/services/GeneralService";
  import Currency from "currency.js";
  import Moment from "moment";

  export default {
    name: 'app-history-page',
    bodyClass: 'history-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        
        isShowReceipt: false,
        receipts: null,
        isPayment: false,
        transactions: [],
        accountBalance: 0,
        fields: [
          {
            key: "alias",
            label: "Student",
            sortable: true
          },
          {
            key: "transactionDate",
            label: "Date",
            sortable: true,
            formatter: "Moment(d).format('LLL')"
          },
           {
            key: "isPayment",
            label: "Trans Type",
            sortable: true
          },
          {
            key: "checkNumber",
            label: "Check #",
            sortable: true
          },
          {
            key: "transactionNumber",
            label: "Trans #",
            sortable: true
          },
          {
            key: "tenderAmount",
            label: "Amount",
            sortable: true,
            cstyle: "background-color:red;",
            formatter: value => { return Currency(value) }
          }, {
           key: "balance",
            label: "Balance",
            sortable: true,
            //cstyle: "background-color:red;",
            formatter: value => { return Currency(value) }
          },
        ],
        
      }
    },
    async created() {
      const data = await Promise.all([
        this.getBalance(),
        //this.getStudents(),
        this.getTransactions('')
      ]);

    },
    computed: {
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      currency(c) {
        return Currency(c);
      },
      html2Text( str ) {
        try {
          str=str.replace(/<br>/gi, "\n");
          str=str.replace(/<p.*>/gi, "\n");
          str=str.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
          str=str.replace(/<(?:.|\s)*?>/g, "");
        } catch (error) {
          return "err:" + str;
        };       
        return str;
      },
      printTransactions() {
        window.print();
      },
      showReceipt(trans) {
        if (trans.receipt) {
          this.isPayment = trans.isPayment;
          this.receipts = trans.receipt;
          this.$bvModal.show("ticket-receipt")
        }
      },
      async getBalance() {

        try {
          let response = await GeneralService.getBalance();
          this.accountBalance = response.data.balance;
        } catch (error) {
          this.notify("danger", error.message);      // todo: update with a better message.
        }
      },
      async getTransactions(badgeNumber) {
        let t = this;

        try {
          let response = await GeneralService.getTransactions(badgeNumber);
          this.transactions = response.data;

          let balance = this.accountBalance;
          for (let i = 0; i < this.transactions.length; i++) {
            this.transactions[i].balance = balance;
            if (this.transactions[i].isPayment) {
              balance = balance - this.transactions[i].tenderAmount;
            } else {
              balance = balance + this.transactions[i].tenderAmount;
            }
          }


        } catch (error) {
          this.notify("danger", error.message);      // todo: update with a better message.
        }
      },
      async getStudents() {
        let t = this;

        try {
          let response = await GeneralService.getStudents();
          t.students = response.data.students;
        } catch (error) {
          // todo: tell the user about this issue...
          console.error(error);
        }
      },
      notify(type, message) {        // fixme: refactor to move this to a component or root
        let t = this;
        let icon = "fa fa-info-circle";

        // if(type === "danger") {
        //   icon = "fa fa-error";
        // } else if(type === "success") {
        //   icon = "fa fa-ok";
        // } else if(type === "error") {
        //   type = "danger";
        //   icon = "fa fa-danger";
        // } else {
        //   icon = "fa fa-warning";
        // }

        t.$notify({
          message: message,
          icon: icon,
          horizontalAlign: 'center',  // right | center | left
          verticalAlign: 'bottom',  // top | bottom
          type: type       // info | warning | danger | success | primary
        })
      }

    }
  }
</script>
<style  lang="scss">



  @media print {

    @page {
      size: auto;
      width: 8.5in;
      height:11in;
      margin-bottom:20px;
      padding:0;
    }

    /* having a bugger of an issue  trying to get the header to space correctly on page 2+*/
    table thead {
      /*display:none !important;*/

      tr {
        margin-top:20px;
      }
      /*page-break-before:always !important;*/
      /*border: 2px solid black;*/
      /*color:red;*/
    }

    .transaction-card {
      border-radius:0 !important;
      border:0 solid white !important;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none !important;
      padding:0;
      margin:0;
      line-height:1em;

      table { page-break-inside:auto }
      tr    { page-break-inside:avoid; page-break-after:auto }
    }

  }

  .receipt-format {
    /*text-align:center;*/
    padding-top:40px;
    padding-left:10px;
    padding-bottom:20px;
    font-family: "Lucida Console", Monaco, monospace ;
    white-space:pre;
    line-height:1.2em;
  }

  .table-format {
    cursor:pointer;
    th:nth-child(6), th:nth-child(7) {
      text-align:right;
      padding-right:10px;
    }
    td:nth-child(6), td:nth-child(7) {
      text-align:right;
      padding-right:10px;
    }
  }


</style>
