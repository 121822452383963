<template>
  <div class="page-header header-filter section-image" filter-color="black">
    <div class="page-header-image" style="background-image: url('/img/ben-white-qDY9ahp0Mto-unsplash.jpg')">
    </div>
    <div class="content">
      <div class="container">
        <div class="col-md-6 col-sm-12 ml-auto mr-auto" style="padding-top:96px;">
          <notifications></notifications>
          <div>
            <h1>Well, Look Who's Here!</h1>
          </div>
          <card type="login" plain>
            <div slot="header" class="logo-container">
              <!--                  {{$root.domain}}-->
              <!--                     <img v-lazy="'img/now-logo.png'" alt="">-->
            </div>
            <div class="mb-2">{{message}}</div>
            <form>
              <fg-input class="no-border input-lg"
                        addon-left-icon="now-ui-icons users_circle-08"
                        v-model="username"
                        type="email"
                        :required="yes"
                        placeholder="Email Address">
              </fg-input>

              <fg-input class="no-border input-lg"
                        addon-left-icon="now-ui-icons text_caps-small"
                        v-model="password"
                        type="password"
                        @keydown.enter="loginClick(username,password)"
                        :required="yes"
                        autocomplete="password"
                        placeholder="Password">
              </fg-input>
              <fg-input v-if="askSignupCode" class="no-border input-lg"
                        ref="signupCodeControl"
                        addon-left-icon="now-ui-icons users_circle-08"
                        v-model="signupCode"
                        @keydown.enter="loginClick(username,password)"
                        type="text"
                        placeholder="Registration Code">
              </fg-input>
            </form>
            <div v-if="errorMessage.length>0">{{errorMessage}}</div>
            <template slot="raw-content">
              <div class="card-footer text-center" style="margin-left:.25em;margin-right:.55em;">
                <a @click="loginClick(username,password)" class="btn btn-primary btn-round btn-lg btn-block">Log Me In!</a>
              </div>
            </template>
            
          </card>
          <div class="link show-hand" style="font-size:1.25em;text-decoration:underline;" @click="forgotPassword()">Forgot Password</div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
  import { Button, Card, FormGroupInput, SFormInput } from '@/components';
  import constants                                    from "@/constants";
  import MainFooter                                   from '@/layout/MainFooter';
  import AuthenticationService                        from "@/services/AuthenticationService";

  export default {
    name      : 'login-page',
    bodyClass : 'login-page',
    components: {
      Card,
      MainFooter,
      constants,
      SFormInput,
      [ Button.name ]        : Button,
      [ FormGroupInput.name ]: FormGroupInput
    },
    data() {
      return {
        title        : constants.PORTAL_APIURL,               // process.env.portal_apiurl, // "Login",
        loginMessage : "Log In",
        errorMessage : "",
        askSignupCode: false,
        signupCode   : "",
        loading      : false,
        isLoading    : "fa fa-spinner  fa-refresh-animate",
        username     : "",
        password     : "",
        domains      : [],
        errors       : null,
        message      : "Please Sign In",
        yes          : true
      };
    },
    mounted() {
      // console.log( JSON.stringify( process.env, null, 2 ) );
      // console.log( JSON.stringify( constants, null, 2 ) )
    },
    methods   : {
      nothing() {
        alert("all done ");
      },
      forgotPassword() {
        this.$router.push( "forgotpassword" );
      },
      async loginClick(userId, pass) {
        let t     = this;
        let creds = {
          email     : userId.toLowerCase(),
          password  : pass,
          signupCode: t.signupCode
        }
        let result;
        // t.message = "";

        if(t.askSignupCode && t.signupCode.length !== 7) {
          alert("Your registration code should be 7 characters.  Be sure to include the dash.");
          return;
        }

        try {
          t.askSignupCode = false;
          result          = await AuthenticationService.login(creds);

          if(result.status === 200) {
            t.message = result.data.message;
            t.$root.login(result.data);
            t.$router.push({path: '/app/main'});   // this throws a google maps api error -- hmmm...
          } else {
            this.$notify(
              {
                message        : result.data.message,
                icon           : 'fa fa-exclamation',
                horizontalAlign: 'center',
                verticalAlign  : 'top',
                type           : "danger"
              })

            t.message = result.data.message;          // guessing this is here if we don't throw an error... ??
          }

        } catch(err) {
          if(err.response) {
            if(err.response.data) {
              if(err.response.data.message) {

                if(err.response.data.unVerified) {
                  this.$notify(
                    {
                      message        : "Check your email for the registration code and enter it here",
                      icon           : 'fa fa-exclamation',
                      horizontalAlign: 'center',
                      verticalAlign  : 'top',
                      type           : "info"
                    })
                  t.askSignupCode = true;
                  t.message       = "We sent your registration code to your email address.  Please check your email, and enter it in the required field";

                  //  this.$nextTick(() => t.$refs.signupCodeControl.focus());

                } else {
                  this.$notify(
                    {
                      message        : err.response.data.message,
                      icon           : 'fa fa-exclamation',
                      horizontalAlign: 'center',
                      verticalAlign  : 'top',
                      type           : "danger"
                    })
                  // t.errorMessage = err.response.data.message;
                }
              }
            }
          }
          // if( err.response.data.message ) {
          //   t.message = err.response.data.message;
          // }

        }

      }
    }
  }
</script>
<style>
</style>
