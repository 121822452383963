/**
 * Created by wscott on 10/2/2017.  Machine: {wolfdev2}
 *
 *  Revision History for Api ::
 *
 *      Initial Writing: 10/2/2017        wjscott
 *      ------------------------------------------------------------------------
 *
 *       1.
 *
 */

import axios from 'axios';
import constants from '@/constants';

export default () => {
  
  return axios
      .create({
                baseURL     : constants.PORTAL_APIURL, // how do we get this in here??
                responseType: 'json',
                headers     : {
                  'Content-Type'  : 'application/json',
                  'Accept'        : 'application/json',
                  'applicationKey': '00000-10000-00000-00000-00030'
                }
              })
  
}
