<template>
  <div class="page-header signup-page section-image">
    <div class="page-header-image"
         style="background-image: url('img/back-to-school-start-MACVKugZ8WQ.jpg')">
    </div>
    <div class="content">
      <div class="container">
        <notifications></notifications>
        <div class="row" style="padding-top:72px;">
          <div class="col-md-4 ml-auto mr-auto">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons" style="color:#faaf3d;font-family:arial;font-size:4em;font-weight:900;">1</i>
              </div>
              <div class="description">
                <h5 class="info-title">First, Let's Create Your Account</h5>
                <p class="description">
                  This is YOUR access to the system.  After registering, we will link your student(s). The School Code is provided on the sign-up sheet you were given at school registration.
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons" style="color:#7fb847;font-family:arial;font-size:4em;font-weight:900;">2</i>
              </div>
              <div class="description">
                <h5 class="info-title">Confirm Your Account</h5>
                <p class="description">
                  Once you complete your registration we will send you a confirmation email with a CODE. You will need this code the first time you log in. If you do not receive this email, please check your <b>SPAM FOLDER</b>.
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons" style="color:#3ab0ce;font-family:arial;font-size:4em;font-weight:900;">3</i>
              </div>
              <div class="description">
                <h5 class="info-title">Sign-in After Registering</h5>
                <p class="description">
                  When you receive the email, return to the login screen and log in as usual, using your email and password. You will be prompted for the code we sent to you via email.
                  <br><br>
                  Welcome aboard!
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mr-auto">
            <div class="card card-signup">
              <div class="card-body">
                <h4 class="card-title text-center">Register<a href="\video\parentRegistration.mp4" target="_blank"><i style="color:red;margin-left:10px;" class="fa fa-film"  v-b-popover.hover.top="'Video: How To Register/Create My Parent Account'"></i></a></h4>
                <form>
                  <fg-input addon-left-icon="now-ui-icons text_caps-small"
                            v-model="form.signupCode"
                            uppercase=true
                            :error="schoolCodeError"
                            placeholder="School Code">
                  </fg-input>
                  
                  <fg-input addon-left-icon="now-ui-icons users_circle-08"
                            v-model="form.firstName"
                            placeholder="Your First Name">
                  </fg-input>
                  
                  <fg-input addon-left-icon="now-ui-icons users_circle-08"
                            v-model="form.lastName"
                            placeholder="Your Last Name">
                  </fg-input>
                  
                  <fg-input addon-left-icon="now-ui-icons ui-1_email-85"
                            v-model="form.email"
                            autocomplete="username"
                            placeholder="Your Email">
                  </fg-input>
                  
                  <fg-input addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                            v-model="form.password"
                            type="password"
                            autocomplete="current-password"
                            :style="passwordMatch"
                            placeholder="Create a Password">
                  </fg-input>
                  
                  <fg-input addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                            v-model="form.confirmPassword"
                            v-if="form.password.length>0"
                            type="password"
                            autocomplete="confirm-password"
                            :style="passwordMatch"
                            placeholder="Confirm Password">
                  </fg-input>
                </form>
                <b-alert variant="danger" :show="showPWMessage" style="width:80%;margin:auto;border-radius:3em;background-color:#F96332;">Passwords Do Not Match?</b-alert>
                <n-checkbox  class="mr-2" v-model="form.agree">
                  <span style="font-size:.9em;font-weight:600;">I agree to the <a href="#">terms and conditions</a>.</span>
                </n-checkbox>
                <div class="card-footer text-center" style="margin-left:.25em;margin-right:1em;">
                  <a v-if="form.agree && (form.password===form.confirmPassword) && !sendingRequest" @click="signup()" class="btn btn-primary btn-round btn-lg btn-block">I Agree, Register Me!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
  import { Button, Card, Checkbox, FormGroupInput } from '@/components';
  import MainFooter                                 from '@/layout/MainFooter';
  import AuthenticationService                      from "@/services/AuthenticationService";
  
  export default {
    name      : 'signup-page',
    bodyClass : 'signup-page',
    components: {
      Card,
      MainFooter,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput
    },
    data() {
      return {
        schoolCodeError: "",
        sendingRequest :  false,
        form           : {
          signupCode     : "",
          firstName      : '',
          lastName       : '',
          email          : '',
          password       : '',
          confirmPassword: '',
          agree          : false
        }
      }
    },
    computed  : {
      passwordMatch() {
        if(this.form.password.length > 0 && this.form.confirmPassword.length > 0) {
          if(this.form.password === this.form.confirmPassword) {
            return "border-radius:5em; background-color:#7fb847;";
          } else {
            return "border-radius:5em; background-color:#F96332;";  // F96332
          }
        }
        return "";
      },
      showPWMessage() {
        if(this.form.password.length > 0 && this.form.confirmPassword.length > 0) {
          if(this.form.password === this.form.confirmPassword) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
    },
    methods   : {
      async signup() {
        let t = this;
        let result;
        
        this.form.signupCode = this.form.signupCode.toUpperCase();        // fixme: make this part of the UI verification
        
        try {
          this.sendingRequest = true;
          this.form.email = this.form.email.toLowerCase();
          result = await AuthenticationService.signup(this.form);
          
          if(result.status === 200) {
            t.message = result.data.message;
            this.$notify(
                {
                  message        : result.data.message,
                  icon           : 'fa fa-exclamation',
                  horizontalAlign: 'center',
                  verticalAlign  : 'top',
                  type           : "success"
                })
            t.$router.push({path: '/login'});   // this throws a google maps api error -- hmmm...
          } else {
            this.$notify(
                {
                  message        : result.data.message,
                  icon           : 'fa fa-exclamation',
                  horizontalAlign: 'center',
                  verticalAlign  : 'top',
                  type           : "danger"
                })
            
            t.message = result.data.message;          // guessing this is here if we don't throw an error... ??
          }
          
        } catch(err) {
          console.log(err);
          
          if(err.response) {
            //console.log("have response")
            if(err.response.data) {
              //console.log("have data", JSON.stringify(err.response.data, null, 2))
              if(err.response.data.message) {
                this.$notify(
                    {
                      message        : err.response.data.message,
                      icon           : 'fa fa-exclamation',
                      horizontalAlign: 'center',
                      verticalAlign  : 'top',
                      type           : "danger"
                    })
                t.message = err.response.data.message;
              }
            }
          }
        }
        this.sendingRequest = false;
      }
    }
  }
</script>
<style>
</style>
