<template>
  <div class="wrapper presentation-page">
    <div class="page-header clear-filter">
      <div class="rellax-header rellax-header-sky">
        <div class="page-header-image" style="background-image: url('img/black.png');">
        </div>
      </div>
      <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
        <parallax class="page-header-image page-header-city" style="background-image: url('img/smiles.jpeg');">
        </parallax>
      </div>
      <h1 class="h3-description rellax-text" data-rellax-speed="-1">Where smiles happen, one meal at a time</h1>
    </div>
  </div>
</template>

<script>
  import { Card, InfoSection } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui'

  export default {
    name: 'presentation',
    bodyClass: 'presentation-page',
    components: {
      Card,
      InfoSection,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
