<template>
  <aside class="dh-frame" :style="frameStyle" @click="frameClicked">
    <div class="dh-titleNumber">{{titleNumber}}</div>
    <h3 :style="h3style">{{title}}</h3>
    
    <!--    <template v-if="items.length>2">-->
    <!--    <ul :style="ulstyle">-->
    <!--      <li v-for="(item,index) in items" :style="isItem(index)">{{item.title}}</li>-->
    <!--    </ul>-->
    <!--    </template>-->
    <template>
      <div class="dh-body" :style="bodyStyle">
        <div v-for="(item,index) in items" :style="ulstyle(index)">
          <ul @click="selectItem(index)">
            <li>
              {{item.title}}
            </li>
            <li>
              {{item.description}}
            </li>
            <li><i>{{item.price[0]}}</i></li>
          </ul>
        </div>
      </div>
    </template>
  </aside>
</template>
<script>
  export default {
    
    props   : {
      title         : {type: String, default: ""},
      titleNumber   : {type: [ String, Number ], default: ""},
      width         : {type: String, default: "200px"},
      height        : {type: String, default: "300px"},
      color         : {type: String, default: "#e3a03b"},
      textColor     : {type: String, default: "white"},
      fontSize      : {type: String, default: "medium"},
      textAlign     : {type: String, default: "left"},
      borderFontSize: {type: String, default: "2em"},
      items         : {type: Array, default: () => []},
      selected      : {type: Number, default: -1}
    },
    computed: {
      frameStyle() {
        let style = "width: " + this.width +
                    "px;height: " + this.height + "px;" +
                    "border-bottom: 6px solid " + this.color +
                    ";border-left: 50px solid " + this.color + ";";
        return style;
      },
      h3style() {
        return "color: " + this.textColor +
               ";font-size:" + this.borderFontSize + ";"
      },
      bodyStyle() {
        return "width: " + (this.width - 60).toString() +
               "px;height: " + (this.height-12).toString() + "px;";
               
      }
    },
    data() {
      return {
        //selected: -1
      }
    },
    methods : {
      ulstyle(itemNumber) {
        let style = "font-size:" + this.fontSize +
                    ";text-align:" + this.textAlign + ";";
        if( this.selected !== -1 ) {
          if(itemNumber === this.selected) {
            style += "border-radius:.5em;background:" + this.color + ";color:" + this.textColor + ";";
          } else {
            style += "color:#bbb;background:white;";
          }
        }
        return style;
      },
      frameClicked() {
        this.$emit("click", this.title);
      },
      selectItem(index) {
        this.selected = index;
      }
      
    }
    
  }
</script>
<style lang="scss">
  
  .dh-frame {
    float: left;
    margin: 1em;
    border-radius: 1em;
    padding: 3px;
  }
  
  .dh-titleNumber {
    position: absolute;
    top: -10px;
    left: -60px;
    padding: 3px;
    border-radius: 50%;
    border: 4px solid #f96332;
    width: 36px;
    height: 36px;
    font: 1.4em Arial, sans-serif;
    text-align: center;
    background: white;
    color: black;
  }
  
  .dh-frame h3 {
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -25px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
  }
  
  /*.dh-frame dh-text {*/
  /*  vertical-align: bottom;*/
  /*}*/
  
  .dh-frame ul {
    vertical-align: middle;
    list-style: none;
    padding-left: 0;
    padding-top: .3em;
    padding-bottom: .3em;
  }
  
  .dh-body li:first-child {
    font-weight: 900;
  }
  
  .dh-body {
    vertical-align: middle;
    display: table-cell;
    padding:2px;
    overflow:auto;
  }


</style>


