<template>
    <footer class="footer"
            :class="{[`footer-${type}`]: type}"
            :data-background-color="backgroundColor">
        <div class="container">
            <nav>
                <ul>
                    <li>
                        <a href="https://www.mySchool.cafe">
                            mySchool.cafe
                        </a>
                    </li>
                    <li>
                        <router-link  to="/about">
                           About
                        </router-link>
                    </li>
                    <li>
                        <router-link  to="/license">
                            License
                        </router-link>
                    </li>
                </ul>
            </nav>
            <div class="copyright">
                &copy; 2018-{{year}},
                <a href="https://www.newcare.international" target="_blank">Newcare International</a>, All Rights Reserved.
<!--                Coded by <a href="https://mySchool.cafe/team" target="_blank">our amazing team</a>-->
            </div>
        </div>
    </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>
