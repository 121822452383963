///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from './Api';

export default {
  
  signup(signupData) {
    const response = Api().post('/signup', signupData);
    return response;
  },
  
  login(credentials) {
    const response = Api().post('/login', credentials);
    return response;
  },
  
  logout(credentials) {
    const response = Api().post('/logout', credentials);
    return response;
  },
  
  lookupSignupCode( signupCode ) {
    const response = Api().post( '/verifySignupCode', signupCode )
    return "";
  },

  // This just takes the current user details (since we have a token) and reauthorize; creating a new
  // token and returning the proper user details.
  authorize() {
    const response = Api().post("/authorize");
    return response;
  },
  
  forgotPassword(email) {
    const response = Api().post("/forgotPassword", {email: email});
    return response;
  },
  resetPassword( email , token , newPassword ) {
    const response = Api().post( "/resetPassword" , {email:email, resetToken: token, newPassword: newPassword });
    return response;
  }
 
}
