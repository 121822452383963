<template>
   <div class="wrapper">
      <div class="page-header page-header-mini">
         <parallax class="page-header-image"
                   style="background-image: url('img/back-to-school-start-MACVKugZ8WQ.jpg')">
         </parallax>
         <div class="content-center">
            <div class="row">
               <div class="col-md-8 ml-auto mr-auto">
                  <!-- <h1 class="title">Helping Hands</h1> -->
                  <br><br>
                  <h4 class="mt-5">Sometimes just a little help can make all the difference...</h4>
               </div>
            </div>
         </div> 
      </div>
      <div class="section">
         <div class="about-office">
            <div class="container">
               <div class="row text-center">
                  <div class="col-md-8 ml-auto mr-auto">
                     <h2 class="title">How to create an account? <a href="\video\parentRegistration.mp4" target="_blank"><i style="color:red;" class="fa fa-film"></i></a></h2>
                     <h4 class="description">Your school provided a sign-up sheet with the school registration code and instructions.  Navigate to the "Register For Access" option (green button above) and enter the school code along with the other requested information. <strong>Click the "movie icon" to see a short video.</strong></h4>
                     <h2 class="title">Then What?</h2>
                     <h4 class="description">We will send you a registration code via email.  Sometimes these emails are intercepted by spam filters, despite our best efforts, so be sure to check your spam folder if you do not receive an email from mySchool.cafe within a few minutes.</h4>
                     <h2 class="title">Got the code, now what?</h2>
                     <h4 class="description">Log into mySchool.cafe as you normally would, using your email address and password.  You will be prompted for the code after logging in. This is a one-time action which activates your account.  The code will not be required after you confirm your account and you can safely delete the email.</h4>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </div>
</template>
<script>
  import { Badge, Button, Card, Checkbox, FormGroupInput, InfoSection, SFormInput } from '@/components';
  import { Option, Select } from 'element-ui'

  export default {
    name      : 'about-us',
    bodyClass : 'about-us',
    components: {
      Card,
      InfoSection,
      Badge,
      SFormInput,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput,
      [ Select.name ]        : Select,
      [ Option.name ]        : Option,
    },
    data() {
      return {
        form: {
          firstName: '',
          email    : '',
          body     : ''
        }
      }
    }
  }
</script>
<style>
</style>
