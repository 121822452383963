<template>
  <div>
    <div class="page-header page-header-smalltop non-printable" >
      <parallax class="page-header-image" style="background-image: url('/img/back-to-school-start-MACVKugZ8WQ.jpg')"></parallax>
      <notifications class="non-printable"></notifications>
    </div>
    <div class="section">

      <div class="row" style="margin-top:-4em;">

        <card v-if="!calendar" class="col-sm-12 col-md-5 ml-auto mr-auto  shadow non-printable"  style="border-radius:1.5em;min-height:600px;">
          <h4 class="info-title mt-0">Pick a calendar</h4>
          <div v-for="(calendar,index) in calendars" :key="index">
            <div>
            <b-button @click="getCalendar(calendar._id)" class=" btn btn-sm btn-fill" style="width:100%;font-size:1.25em;border-radius:1em;">{{calendar.title}}</b-button>
            <p class="ordercalendar-description" style="font-size:1em;font-weight:500;">{{calendar.description}}</p>
            </div>
            <hr>
          </div>
        </card>

        <card v-if="calendar && students && students.length > 0 && !isShowWeeklyOrderCalendar && !isShowMonthlyItemCalendar" class="col-xs-12 col-sm-12 col-md-3 ml-auto mr-auto shadow non-printable" :class="(!student) ? 'mr-auto col-md-5' : ''" style="border-radius:1.5em;">
          <div class="text-center" style="margin-bottom:12px;">
            <i v-if="student" class="fa fa-calendar show-hand" v-b-popover.hover.top="'Show YOUR Order Summary'" style="color: rgb(31,77,133);height:20px;margin-top:10px;margin-right:8px;font-size:1.5em;" @click="showWeeklyOrderCalendar()"><span style="font-size:.7em;padding-left:3px;">{{student.firstName}}'s Orders</span></i>
            <i v-if="student" class="fa fa-calendar-o show-hand" v-b-popover.hover.top="'Show Monthly AVAILABLE Meals'" style="color: rgb(190,22,27);height:20px;margin-top:10px;margin-right:8px;font-size:1.5em;" @click="showMonthlyMealCalendar()"><span style="font-size:.7em;padding-left:3px;">Monthly Calendar</span></i>
            <i class="fa fa-info-circle show-hand" style="color:rgb(227,160,59);height:20px;margin-top:10px;margin-right:8px;font-size:1.5em;padding-left:10px;" v-b-popover.hover.bottom="'Show Nutrient Listing'" @click="nutrients()"><span style="font-size:.7em;padding-left:3px;">Nutrients</span></i>
            <i class="fa fa-calendar-check show-hand" style="color:rgb(127,184,71);height:20px;margin-top:10px;margin-right:8px;font-size:1.5em;padding-left:10px;" v-b-popover.hover.bottom="'Select Another Meal Calendar'" @click="selectNewCalendar()"><span style="font-size:.7em;padding-left:3px;">Calendar Select </span></i>
          </div>

          <div class="mb-3">
            <h3 class="card-title  mb-1 mt-0">Students</h3>
            <div v-for="(student,studentIndex) in students" :key="studentIndex" style="margin-top: 0;margin-right:1em;width: 97px; display: inline-block;">
              <button class="btn btn-sm mb-2" :class="(isSelectedStudent(student.studentId)) ? 'btn-primary shadow' : 'btn-outline'" style="border-radius:.5em;width:100%;margin:0;" @click="selectStudent(student)">
                {{student.firstName}}
              </button>
            </div>
          </div>

          <div v-if="student!==null" class="non-printable">
            <sweet-calendar
              :eventCategories="eventCategories"
              :events="events"
              :initialDate="calendar.calendarStartDate"
              :beginDate="calendar.calendarStartDate"
              :endDate="calendar.calendarEndDate"
              :enableControls="enableCalendarControls"
              cursor="not-allowed"
              eventCursor="pointer"
              :selectedDate="new Date()"
              @click="selectCalendarDay($event)"
              ref="calendar"
            />
            <div style="display:block;text-align:center;margin-top:0;">
              <p>Order Cutoff is 7am</p>
              <div style="border-radius:.75em;display:inline-block;height:20px;width:100px;vertical-align:middle;text-align:center;background:#39a6c2;color:white;margin-right:.5em;">
                Available
              </div>
              <div style="border-radius:.75em;display:inline-block;height:20px;width:100px;vertical-align:middle;text-align:center;background:#7fb847;color:white;margin-left:.5em;">
                Ordered
              </div>
              <hr>
              <p class="description" style="line-height:.9em;text-align:center;">Click the calendar days above to order or view a previous order.</p>
            </div>
            <h3 class="mb-0 mt-0" style="text-align:center">{{calendar.title || ""}}</h3>
            <p style="font-weight:300;text-align:center;line-height:.9em;">
              {{calendar.description}}
            </p>
          </div>
          <div v-if="student===null">
            <p style="font-weight:500;text-align:center">Please select a student above</p>
          </div>
        </card>

        <card v-if="calendar && student && !isShowWeeklyOrderCalendar && !isShowMonthlyItemCalendar" class="col-xs-12 col-sm-12 col-md-8 mr-auto non-printable" style="border-radius:2em;">
          <h3 class="title mb-0" style="margin-top:-30px;">
            <!-- <div v-if="isShowBalance" style="float:right;font-size:.7em;" :style="(accountBalance<0)?'color:red;':''">Balance: {{currency(accountBalance)}}</div> -->
            <div v-if="accountBalance<5" style="float:right;font-size:.7em;color:red;">Low Balance: {{currency(accountBalance)}}</div>
            <b-button class="btn btn-sm" style="border-radius:1em;" @click="doneOrdering()">All Done Ordering</b-button>
            {{student.firstName}} {{student.lastName}}
            <small> ( {{student.teacher}} / {{student.homeRoom}})</small>
          </h3>
          <div v-if="(menu!==null) ">
            <div>
              <h4 class="title mb-0" style="color:red;" v-if="accountBalance<5">LOW BALANCE: Please Reload Your Account</h4>

              <item-selection-card
                :class="isShadow(moment(menu.menuDate).format('dddd'))"

                :orderDate="moment(menu.menuDate).add(12,'hours')"
                :color="dayColor[moment(menu.menuDate).format('dddd')]"
                :choices="choices"
                :priceLevel="student.priceLevel"
                :disabled="isOrderingDisabled" 
                @add="addItem( $event )"
              >
              </item-selection-card>
            </div>

            <div v-if="order">
              <receipt-card
                color="#0078D4"
                :orderDate="menu.menuDate"
                :order="order"
                title="Receipt"
                :orderLabel="orderLabel"
                :disabled="isOrderEditDisabled"
                @delete="deleteItem($event)"
                @cancel="cancelOrder($event)"
                @order="placeOrder($event)"
                @enable="enableOrder($event)"
              >
              </receipt-card>
            </div>
          </div>
          <div v-else style="height:400px;">
            <p style="height:100%;font-size:2em;line-height:400px;text-align:center;">Please select a day</p>
          </div>
        </card>
      </div>

      <div v-if="isShowMonthlyItemCalendar" class="monthly-calendar col-md-11 mr-auto ml-auto">
        <div class="noprint">
          <b-button class="btn btn-success btn-sm btn-fill" @click="isShowMonthlyItemCalendar=false">Done</b-button>
        </div>
        <monthly-item-calendar
          :calendarItems="calendar"
          :config="monthlyItemCalendarConfig"
          @click="itemCalendarSelectDay( $event )"
        />
      </div>

      <div class="col-md-11 mr-auto ml-auto" v-if="isShowWeeklyOrderCalendar && !isShowMonthlyItemCalendar" >
        <monthly-order-calendar-card 
          v-if="isShowWeeklyOrderCalendar" 
          class="col-md-12" 
          style="border-radius:1em;" 
          :student="student" 
          :orders="student.orders" 
            @click="isShowWeeklyOrderCalendar=false;selectCalendarDay($event)"
          @done="isShowWeeklyOrderCalendar=false;"
        />

      </div>
    </div>
  </div>
</template>
<script>

  import { Button, CalendarDay, Card, ChoiceSelectionCard, DailyMenuSelectionCard, FormGroupInput, TabPane, Tabs } from '@/components';
  import ItemSelectionCard                                                                                         from "@/components/Cards/ItemSelectionCard.vue";

  import MenuItemWithExtras       from "@/components/Cards/MenuItemWithExtras.vue";
  import ReceiptCard              from "@/components/Cards/ReceiptCard.vue";
  import MonthlyOrderCalendarCard from "@/components/Cards/MonthlyOrderCalendarCard.vue";
  import MonthlyItemCalendar      from "@/components/Cards/MonthlyItemCalendar";
  import GeneralService           from "@/services/GeneralService";
  import Currency                 from "currency.js";
  import _                        from "lodash";
  import Moment                   from "moment";
  import { Glide, GlideSlide }    from 'vue-glide-js'
  import 'vue-glide-js/dist/vue-glide.css'
  import { Calendar }             from 'vue-sweet-calendar';
  import 'vue-sweet-calendar/dist/SweetCalendar.css';

  export default {
    name      : 'app-meals-page',
    bodyClass : 'meals-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [ Button.name ]        : Button,
      [ FormGroupInput.name ]: FormGroupInput,
      ChoiceSelectionCard,
      CalendarDay,
      DailyMenuSelectionCard,
      MenuItemWithExtras,
      ItemSelectionCard,
      MonthlyOrderCalendarCard,
      ReceiptCard,
      [ Glide.name ]         : Glide,
      [ GlideSlide.name ]    : GlideSlide,
      SweetCalendar          : Calendar,
      MonthlyItemCalendar
    },
    data() {
      return {
        slidesPerView         : 1,
        myDay                 : this.moment(),
        daySelection          : "Select a Day Above",
        selected              : [ -1, -1, -1, -1, -1, -1 ],
        dayColor              : {
          Monday   : "#39a6c2",
          Tuesday  : "#5e3696",
          Wednesday: "#1f4d85",
          Thursday : "#7fb847",
          Friday   : "#e3a03b",
          Saturday : "#5d3c2e",
          Sunday   : "#f50404"
        },
        monthlyItemCalendarConfig: {
          isShowMenuDescription: true,
          isShowItemDescription: true,
          isShowItemPrice      : false,
          enablePrintCalendar  : true,
          enableNextButton     : false,
          enablePrevButton     : false,
          isShowCalenderTitle  : true
        },
        enableCalendarControls   : false,
        calendars                : [],
        calendar                 : null,
        student                  : null,
        students                 : [],
        accountBalance           : 0,
        isShowWeeklyOrderCalendar: false,
        isShowMonthlyItemCalendar: false,
        taxRate                  : 0,
        orderChanged             : false,
        dailyOrderLimit          : 99.00,           // no limit
        isShowBalance            : true,
        isOrderEditDisabled      : false,
        isOrderingDisabled       : false,
        orderLabel               : "Place Order",
        categories               : null,
        order                    : null,
        orders                   : [],
        weeklyOrders             : [],
        orderDate                : "",
        orderTotal               : 0,
        choices                  : [],
        menu                     : null,
        always                   : [],              // the always available items.
        drinks                   : [],
        condiments: [],
        eventCategories          : [
          {
            id             : 1,
            title          : "Items Available",
            textColor      : "white",
            backgroundColor: "#39a6c2"
          },
          {
            id             : 2,
            title          : "Ordered",
            textColor      : "white",
            backgroundColor: "#a0e14f"
          },
          {
            id             : 3,
            title          : '',
            textColor      : 'white',
            backgroundColor: '#7fb847'
          },
          {
            id             : 4,
            title          : '',
            textColor      : 'white',
            backgroundColor: '#ff813a'
          },
          {
            id             : 5,
            title          : '',
            textColor      : 'white',
            backgroundColor: '#555555'
          },
          {
            id             : 6,
            title          : '',
            textColor      : 'white',
            backgroundColor: '#e5e5e5'
          }
        ],
        events                : []
      }
    },
    async created() {
      await this.getCategories(); 
      const data = await Promise.all([
                                       this.getBalance(),
                                       this.getAlwaysItems(),
                                      //  this.getAlways(),
                                      //  this.getDrinks(),
                                       this.getCalendars(),
                                       this.getStudents()
                                     ]);
    },
    async beforeRouteLeave( to , from, next ) {
      if( !this.orderChanged ) {
        return next();
      }
      if( await this.$root.confirmYesNo('You have unsaved changes on your order, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    computed  : {
      remainingBalance() {
        return this.accountBalance - this.orderTotal;
      }
    },
    methods   : {
      moment(d) {
        return Moment(d);
      },
      isShadow(d) {
        if(d === this.daySelection) {
          return "shadow";
        }
      },
      async showMonthlyMealCalendar() {
        // await this.$root.alert( "Coming Soon" );
        this.isShowMonthlyItemCalendar = true;
      },
      qtyOnOrder( mi ) {
console.log( mi );
        return mi.menuItemId;
      },
      isSelectedStudent(studentId) {
        if(!this.student) {
          return false;
        }

        if(this.student.studentId === studentId) {
          return true;
        }
        return false;
      },
      nutrients() {
        window.open(`/doc/ingredients-${this.$root.account.tenant.domainName}.pdf`, "_blank");
      },
      itemCalendarSelectDay( d ) {
        this.isShowMonthlyItemCalendar = false;
        let selectedDay = new Date( `${d.year}-${d.month}-${d.day}` );
        this.selectCalendarDay( selectedDay )
      },
      async showWeeklyOrderCalendar() {
        let t=this;
        if( this.student.orders.length <= 0 ) {
          await this.$root.alert( "No orders yet", "warning" );
          return;
        }
        this.weeklyOrders = this.orders;
        t.isShowWeeklyOrderCalendar = true;
      },
      trySetOrderDisable(status) {
        this.isOrderEditDisabled = status;
      },
      newTransaction() {

        this.order = {
          header    : [],
          trailer   : [],
          subTotal  : 0,
          tax       : 0,
          total     : 0,
          totalItems: 0,
          priceLevel: 0,
          items     : []
        };

        this.orderChanged = false;
        this.trySetOrderDisable(false);
        this.calculateOrderTotal();
      },
      calculateOrderTotal() {
        let t              = this;
        t.order.subTotal   = _.sumBy(t.order.items, function(item) {
          return item.price * item.qty;
        })
        t.order.totalItems = _.sumBy(t.order.items, function(item) {
          return item.qty;
        })
        t.order.tax        = t.currency(t.order.subTotal).multiply(t.taxRate);
        t.order.total      = t.currency(t.order.subTotal).add(t.order.tax);
      },
      async getWeeklyOrders() {

        try {
          let response = await GeneralService.getWeeklyOrders( this.moment(this.myDay).format( "YYYY-MM-DD" ) , this.student.studentId );
          this.weeklyOrders = response.data;
        } catch( error ) {
          console.log( error.message );
        }
      },
      async cancelOrder(order) {
        if(order._id) {
          if( new Date(order.orderDate).getTime() <= new Date().getTime()) {
            return await this.$root.alert("Cannot Remove A Sent Past Order");
          }

          if( await this.$root.confirmYesNo("Cancel This EXISTING Order?")) {
            await this.removeOrder(order);
            this.newTransaction();
            this.notify("success", "Your Order For This Day Has Been Canceled");
          }
        } else {
          if(order.items.length > 0) {
            this.newTransaction();
            this.notify("success", "Starting Over...");
          }
        }
      },
      async replaceOrder(order) {
        if(!order._id) {
          return await this.$root.alert("This order can not be updated");
        }
        await this.updateOrder(order);
        this.newTransaction();
        this.notify("success", "Your Order Has Been Updated");
      },
      enableOrder(order) {
        if(new Date(order.orderDate).getTime() >= new Date().getTime()) {       // todo: refine this to be 7am
          this.trySetOrderDisable(false);
        }
      },
      async placeOrder(order) {

        if(this.order.items.length === 0) {
          return;
        }

        if( this.accountBalance < (-10)) {
          await this.$root.alert("Please reload your account to place an order - I'll take you there now");
          this.orderChanged = false;
          this.$router.push('/app/profile');
          return;
        }

        if(!this.hasDrink()) {
          if(! await this.$root.confirmYesNo("No drink on this order, continue anyway? ")) {
            return;
          }
        }

        order.accountNumber  = this.student.accountNumber;
        order.badgeNumber    = this.student.badgeNumber;
        order.alias          = this.student.firstName + " " + this.student.lastName;
        order.grade          = this.student.grade;
        order.teacher        = this.student.teacher;
        order.homeRoom       = this.student.homeRoom;
        order.calendarNumber = this.calendar.calendarNumber;

        this.calculateOrderTotal();

        let newOrder = {};
        Object.assign(newOrder, order);

        if(order._id) {
          order = await this.updateOrder(newOrder);
          this.notify("success", "Your Order Has Been Updated");
        } else {
          order = await this.postOrder(newOrder);
          this.notify("success", "Your Order Has Been Placed");
        }

        this.newTransaction();
        this.buildEvents(this.calendar);

        this.selectCalendarDay(Moment(newOrder.orderDate));
      },
      addItem(item, usePriceLevel) {
        let t = this;

        if(this.isOrderEditDisabled === true) {
          return;
        }

        if(!this.order) {
          this.newTransaction();
        }

        this.orderChanged = true;
        let existing      = _.find(this.order.items, {itemNumber: item.itemNumber});

        this.order.orderDate = item.orderDate;      // order has date of last item added.

        if(existing) {
          // todo: see what our max item is.
          if(existing.qty < item.maxChoice) {
            existing.qty++;
          }
        } else {
          this.order.items.push(item);
          // console.log( item );
          this.order.items = _.sortBy(this.order.items, "itemNumber");
        }

        this.calculateOrderTotal();

      },
      deleteItem(itemNumber) {
        if(this.order.items.length === 0) { return; }
        let existing = _.find(this.order.items, {itemNumber: itemNumber});
        if(existing) {
          this.$emit("remove", itemNumber);
          existing.qty--;
          if(existing.qty <= 0) {
            _.remove(this.order.items, function(item) {
              return item.itemNumber === itemNumber;
            })
          }
        }
        this.calculateOrderTotal();
      },
      nextDay() {
        this.myDay = this.moment(this.myDay).add(1, 'day');
      },
      async selectStudent(student) {
        // if(this.isOrder()) {
        if(this.isOrderEditDisabled === false && this.orderChanged === true) {
          await this.$root.alert("Complete Your Order Before Changing Students");
          return;
        }
        //}

        this.student = student

        this.newTransaction();

        if(!student.orders) {
          await this.getOrders(this.student);
        }

        this.selectCalendarDay(this.myDay);
        this.buildEvents(this.calendar);

      },
      async doneOrdering() {
        if(this.isOrder()) {
          if(!this.isOrderEditDisabled) {
            await this.$root.alert("Complete The Current Order First");
            return;
          }
        }
        this.$router.push('/app/main')
      },
      selectMenu(menu) {
// console.log( "selectMenu()" , menu );
        this.menu    = menu;
        this.choices = [];
        this.choices.push({title: "Meal Selections", usePriceLevel: true, items: menu.choices.filter((i) => { return i.itemNumber > 0})});
        if( this.condiments ) {
         this.choices.push({title: "Condiments", usePriceLevel: false, items: this.condiments.filter((i) => { return i.itemNumber > 0})}); 
        }
        this.choices.push({title: "Drink Selections", usePriceLevel: false, items: this.drinks.filter((i) => { return i.itemNumber > 0})});

        let title = "A La Carte";

        if( this.categories ) {
          let x = _.find( this.categories , { category: "always" });
          if( x ) {
            title = x.title || "A La Carte";
          }
        }

        this.choices.push({title: title, usePriceLevel: false, items: this.always.filter((i) => { return i.itemNumber > 0})});

        
      },
      async selectCalendarDay(d) {
// console.log( "selectCalendarDay()");
        if(this.isOrder()) {
          if(!this.isOrderEditDisabled) {
            await this.$root.alert("Complete The Current Order First");
            return;
          }
        }

        this.myDay = d;

        let menu;
        try {
          menu = _.find(this.calendar.menu, function(m) {
            return Moment(m.menuDate).add(12,"hours").format( "YYYY-MM-DD") === Moment(d).format("YYYY-MM-DD");
          });
        } catch(error) {
          menu = null;
          console.error(error);
        }
        if(menu && menu.choices.length > 0) {
          if(this.isOrder()) {
            this.newTransaction();
          }
          this.selectMenu(menu);          // since we use the menu.menuDate for the order, gotta have one of these...

          if(this.student) {
            if(this.student.orders) {
              let order = _.find(this.student.orders, function(order) {
                return Moment(order.orderDate).add(12,"hours").format("YYYY-MM-DD") === Moment(d).format("YYYY-MM-DD");
              });
              this.isOrderingDisabled = false;
              if(order) {
                this.trySetOrderDisable(true);
                this.order = order;
                this.calculateOrderTotal();
              } else {
                this.trySetOrderDisable(false);
                this.isOrderingDisabled = false;
              }

              let today = new Date();
              let cutoff = 7;

              if (Moment(d).format("YYYY-MM-DD") <= Moment(today).format("YYYY-MM-DD")) {
                if (Moment(d).format("YYYY-MM-DD") === Moment(today).format("YYYY-MM-DD")) {
                  if (today.getHours() >= cutoff) {
                    this.isOrderingDisabled = true;
                  }
                } else {
                  this.isOrderingDisabled = true;
                }
              }

            }
          }
        } else {
          this.menu = null
          this.trySetOrderDisable(true);
          this.isOrderingDisabled = true;
        }

        if( new Date(d).getTime() <= new Date().getTime()) {
          if( new Date().getHours() < 7 ) {
            this.orderLabel = "Place Order"
          } else {
            this.orderLabel = "";
            this.isOrderEditDisabled = true;
          }
        } else {
          this.orderLabel = "Place Order";
        }
      },
      hydrateOrderCalendar(calendar) {

        this.calendar               = calendar;
        this.enableCalendarControls = false;

        if(!calendar) {
          return;
        }

        try {

          this.buildEvents(calendar);

          if(new Date(calendar.calendarStartDate).getMonth() !== new Date(calendar.calendarEndDate).getMonth()) {
            this.enableCalendarControls = true;
          }

        } catch(error) {
          // todo: tell the user something about this issue.
          console.error(error);
        }
      },
      buildEvents(calendar) {
        this.events = [];

        const ItemsAvailable = 1;      // todo: make these for the order calendar...
        const Ordered        = 2;

        if(!calendar) {
          return;
        }

        let today = new Date().getTime();
        let cat;
        if(calendar.menu) {
          for(let m of calendar.menu){
            if(new Date(m.menuDate).getTime() >= today) {
              if(m.choices.length > 0) {      // complete?
                cat = 1;
              } else {
                cat = 0;
              }
            } else {
              cat = 6;
            }
            //this.moment(m.menuDate).tz(this.$root.account.tenant.TZ).add(12,"hours"),  
            let event = {
              title     : m.title || "",
              start     :  Moment(m.menuDate).add(12,"hours"),  // m.menuDate,
              end       :  Moment(m.menuDate).add(12,"hours"),  //m.menuDate,
              repeat    : "never",
              categoryId: cat
            };
            this.events.push(event);
          }
        }

        if(this.student) {
          if(this.student.orders) {
            for(let m of this.student.orders){

              if(new Date(m.orderDate).getTime() >= today) {
                let cat     = 3;
                let removed = _.remove(this.events, {start: m.orderDate});        // pull the previous one out.
                let event   = {title: "order", start: m.orderDate, end: m.orderDate, repeat: "never", categoryId: cat};
                this.events.push(event);
              }
            }
          }
        }

      },
      currency(c) {
        return Currency(c);
      },
      isOrder() {
        if(!this.order) {      // no object = no order.
          return false;
        }
        if(this.order.items.length === 0) { // no items, no order
          return false;
        }
        return true;
      },
      hasDrink() {
        if(!this.order) {
          return false;
        }
        if(_.find(this.order.items, {itemCategory: "drinks"})) {
          return true;
        }
        return false;
      },
      hasOrder(student, orderDate) {

        if(!student) {
          return false;
        }
        if(!student.orders) {
          return false;
        }

        let order = _.find(student.orders, {orderDate: orderDate});

        if(order) {
          return true;
        }

        return false;

      },
      async getBalance() {

        try {
          let response        = await GeneralService.getBalance();
          this.accountBalance = response.data.balance;

// console.log( "balance = " , response.data );

        } catch(error) {
          this.notify("danger", error.message);      // todo: update with a better message.
        }
      },
      async getAlwaysItems() {
        let d,a,c;
        try {
          [ d,a,c ] = await Promise.all([ 
              GeneralService.getMenuItems("drinks"),
              GeneralService.getMenuItems("always"),
              GeneralService.getMenuItems("condiments")
          ])
        } catch( error ) {
          console.log( error.message );
        }
        this.drinks     = d.data;
        this.always     = a.data;
        this.condiments = c.data;
      },
      async getCategories() {
        try {
          let response = await GeneralService.getCategories();
          this.categories = response.data;
        } catch(error) {
          console.log( error.message );
        }
      },
      async getMenuItems(category) {
        try {
          let response = await GeneralService.getMenuItems(category);     // comma sep list (or empty for default)
          return response.data;

        } catch(error) {
          this.notify("danger", error.message);      // todo: update with a better message.
        }
      },
      async selectNewCalendar() {
        this.calendar = null;
        this.students.forEach(function(student) {
          student.orders = null;
        })
      },
      async getCalendar(id) {
        try {
          let response = await GeneralService.getOrderCalendar(id);
          this.hydrateOrderCalendar(response.data);

          let day = this.myDay; // Moment();

          if(day < response.data.calendarStartDate) {
            day = response.data.calendarStartDate
          } else if(day > response.data.calendarEndDate) {
            day = response.data.calendarEndDate
          }

          if(this.students.length > 0) {
            await this.selectStudent(this.students[ 0 ]);
          }

          this.selectCalendarDay(day);

        } catch(error) {
          this.notify("danger", `getCalendar(${id}) Unable To Retrieve Calendar.` + error.message);
        }
      },
      async getCalendars() {
        try {
          let response   = await GeneralService.getOrderCalendars();
          this.calendars = response.data;
        } catch(error) {
          console.error(error);
          this.notify("danger", "getCalendars() Unable To Retrieve A Order Calendar. " + error.message);
        }
      },
      async getOrders(student) {

        if(!this.calendar)     // we get pinged on page load.
        {
          return;
        }

        try {
          let response   = await GeneralService.getOrders(this.calendar.calendarNumber, student.badgeNumber);
          student.orders = response.data;

          this.hydrateOrderCalendar(this.calendar)
          // todo: hydrate orders here...

        } catch(error) {
          console.error(error);
          this.notify("danger", "getOrders() Unable To Retrieve Student Orders " + error.message);
        }
      },
      async postOrder(order) {

        try {
          let result = await GeneralService.postOrder(order);
          await this.getOrders(this.student);
        } catch(error) {
          console.error(error);
          if( error.response ) {
            console.log( error.response );
            if( error.response.data  ) {
              if( error.response.status === 409 ) {
                await this.$root.alert( "It appears an order already exists for this day.  Please make sure you do not have multiple browsers open trying to do the same task.  THIS order will be ignored and your previous order remains. I will attempt to retrieve the other orders when you press OK.  You can also log out and try again if this issue persists." );
                await this.getOrders(this.student);
              } else {
                this.notify( "danger" , error.response.data.message );
              }
            } else {
             his.notify("danger", error.message ); 
            }
          } else {
            this.notify("danger", "postOrder() Unable To Save This Order " + error.message);
          }
        }
      },
      async updateOrder(order) {
        try {
          let result = await GeneralService.updateOrder(order);
          await this.getOrders(this.student);
        } catch(error) {
          console.error(error);
          this.notify("danger", "updateOrder() Unable To Save This Order " + error.message);
        }
      },
      async removeOrder(order) {
        try {
          let result = await GeneralService.deleteOrder(order._id);
          await this.getOrders(this.student);
        } catch(error) {
          console.error(error);
          this.notify("danger", "updateOrder() Unable To Save This Order " + error.message);
        }
      },
      async getStudents() {

        let t = this;

        try {
          let response  = await GeneralService.getStudents();
          this.students = response.data.students;
        } catch(error) {
          // todo: tell the user about this issue...
          console.error(error);
        }
      },
      makeCalendar(d) {

      },
      notify(type, message) {        // fixme: refactor to move this to a component or root
        let t    = this;
        let icon = "fa fa-info-circle";
        
        // if(type === "danger") {
        //   icon = "fa fa-error";
        // } else if(type === "success") {
        //   icon = "fa fa-ok";
        // } else if(type === "error") {
        //   type = "danger";
        //   icon = "fa fa-danger";
        // } else {
        //   icon = "fa fa-warning";
        // }
        
        t.$notify({
                    message        : message,
                    icon           : icon,
                    horizontalAlign: 'center',  // right | center | left
                    verticalAlign  : 'bottom',  // top | bottom
                    type           : type       // info | warning | danger | success | primary
                  })
      }
      
    }
  }
</script>
<style scoped lang="scss">

  .ordercalendar-description {
    /*font-family: "Arial";*/
    white-space:pre-line;
    text-align:center;
    font-weight:300;
    line-height:1.25em;
  }

  .monthly-calendar {
    padding:.5em;
  }

  /*body { margin: 0; }*/

table { width: 100%; box-sizing: border-box; box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.1); font-family: 'Ubuntu'; /*border-collapse: collapse;*/ border-spacing: 0; background-color: #333333; }

th, td { border: 1px solid rgba(255,255,255,0.1); box-sizing: border-box; }

th { text-transform: uppercase; font-size:10px; font-weight:700; padding: 10px 0; color: rgba(255,255,255,0.5); background-color: #292929; letter-spacing: 1px; }

td { width: 14.285%; transition: all 0.3s; font-size: 14px; color: rgba(255,255,255,0.6); font-weight: 400; font-size: 14px; padding: 1.5% 1.5% 5%; vertical-align: initial; padding: 1.5% 0 ; height: 75px; }

.day:hover { background-color: rgba(0,0,0,0.2); cursor:pointer; }

.today { color: #FFF; background-color: rgba(0,0,0,.25) !important; }

span.number { margin-left: 10% }

span.event { height: 4px; background-color: rgba(0,0,0,.3); display: block; margin: 5px 10%; border-radius: 2px; background-color: #91c33b; }

span.event-multiday { margin: 5px -2px; border-radius: 0; }
span.event-multiday-start { margin-right: -4px;  }
span.event-multiday-finish { margin-left: -4px;  }

span.event-ghost { background-color:transparent; }


  /*******************************************************************/


  @media print {


    /** { margin: 0 !important; padding: 0 !important; }*/
    /*#controls, .footer, .footerarea{ display: none; }*/
    /*html, body {*/
    /*  !*changing width to 100% causes huge overflow and wrap*!*/
    /*  height:100%;*/
    /*  overflow: hidden;*/
    /*  background: #FFF;*/
    /*  font-size: 9.5pt;*/
    /*}*/

    /*.template { width: auto; left:0; top:0; }*/
    /*img { width:100%; }*/
    /*li { margin: 0 0 10px 20px !important;}*/

    /****************************************************/

    .logo {
      width: 300px !important;
      max-width: 300px !important;
    }

    .non-printable, noprint, #nav, footer, #footer, .footer {
      display: none;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }

    .col-bg.width-20 {
      max-width: 20%;
      width: 20%;
      flex: 0 0 20%;
      border: 1px solid rgba(0, 0, 0, .5);;
      border-right: none;
    }

    .col-bg.width-20:last-child {
      border-right: 1px solid rgba(0, 0, 0, .5);;
    }

    .week-title {
      color: #333 !important;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, .5);
    }

    .week-content .item-detail {
      border-color: rgba(0, 0, 0, .5) !important;
    }
  }

  .logo {
    max-width: 500px;
  }

  .student-info {
    font-size: 20px;
    text-align: left;
    @media only screen and (max-width: 574px) {
      margin-top: 20px;
    }
  }

  .btn-outline-primary {
    color: rgb(31,177,133);
    border-color: rgb(31,177,133);
    min-width: 130px;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:hover,
  .btn-outline-primary:active {
    background-color: rgb(31,177,133);
    border-color: rgb(31,177,133);

  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(31,177,133, 0.5) !important;
  }

  // See the OrderCalendarCard for info on the day color numbers
  .week-title {
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 600;
    font-size: 15px;
    &.monday {
      background: rgb(237,50,55);
      color: #fff;
    }
    &.tuesday {
      background: rgb(57,166,194);
      color: #fff;
    }
    &.wednesday {
      background: rgb(94,54,150);
      color: #fff;
    }
    &.thursday {
      background: rgb(31,177,133);
      color: #fff;
    }
    &.friday {
      background: rgb(127,184,71);
      color: #fff;
    }
  }

  .week-title ~ .week-title {
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 600;
    font-size: 15px;
  }

</style>
