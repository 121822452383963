import Vue         from "vue";
import Router      from "vue-router";
import AppFooter   from "./layout/AppFooter.vue";
import AppNavbar   from "./layout/AppNavbar.vue";
// import AppNavbar from "./layout/AppNavbar.vue";
import MainFooter  from "./layout/MainFooter.vue";
import MainNavbar  from "./layout/MainNavbar.vue";
import ContactUs   from "./pages/app/ContactUs.vue";
import AppHelpPage from "./pages/app/HelpPage.vue";

import MainPage        from "./pages/app/MainPage.vue";
import MealsPage       from "./pages/app/MealsPage.vue";
import HistoryPage     from "./pages/app/HistoryPage.vue";
import AppPaymentsPage from "./pages/app/PaymentsPage.vue";
import ProfilePage     from "./pages/app/ProfilePage.vue";
import Presentation    from "./pages/Presentation.vue";
import AboutUs         from "./pages/public/AboutUs.vue";
import Help            from "./pages/public/Help.vue";
import License         from "./pages/public/License.vue";

import LoginPage  from "./pages/public/LoginPage.vue";
import SignUpPage from "./pages/public/SignUpPage.vue";
import ForgotPasswordPage from "./pages/public/ForgotPasswordPage.vue";

//
// import BlogPost from "./pages/example-pages/BlogPost.vue";
// import BlogPosts from "./pages/example-pages/BlogPosts.vue";

// import LandingPage from "./pages/example-pages/LandingPage.vue";

// import PricingPage from "./pages/example-pages/PricingPage.vue";
// import EcommercePage from "./pages/example-pages/EcommercePage.vue";
// import ProductPage from "./pages/example-pages/ProductPage.vue";
// import MainPage from "./pages/app/MainPage.vue";

Vue.use(Router);

let routeConfig = {
  
  mode          : "history",
  routes        : [
    {
      path      : "/login",
      name      : "login",
      components: {default: LoginPage, header: MainNavbar},
      props     : {header: {colorOnScroll: 60}}
    },
    {
      path      : "/signup",
      name      : "signup",
      components: {default: SignUpPage, header: MainNavbar},
      props     : {header: {colorOnScroll: 60}}
    },
    {
      path      : "/forgotpassword",
      name      : "forgotpassword",
      components: {default: ForgotPasswordPage, header: MainNavbar},
      props     : {header: {colorOnScroll: 50}}
    },
    {
      path      : "/",
      name      : "presentation",
      components: {default: Presentation, header: MainNavbar, footer: MainFooter},
      props     : {header: {colorOnScroll: 50}}
    },
    {
      path      : "/about",
      name      : "about",
      components: {default: AboutUs, header: MainNavbar, footer: MainFooter},
      props     : {header: {colorOnScroll: 50}}
    },
    {
      path      : "/help",
      name      : "help",
      components: {default: Help, header: MainNavbar, footer: MainFooter},
      props     : {header: {colorOnScroll: 60}}
    },
    {
      path      : "/license",
      name      : "license",
      components: {default: License, header: MainNavbar, footer: MainFooter},
      props     : {header: {colorOnScroll: 60}}
    },
    {
      path      : "/contact",
      name      : "contact",
      components: {default: ContactUs, header: MainNavbar, footer: MainFooter},
      props     : {footer: {type: 'default'}}
    },
    // {
    //   path      : "/landing",
    //   name      : "landing",
    //   components: { default: LandingPage, header: MainNavbar, footer: MainFooter },
    //   props     : { footer: { type: 'default' }, header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/pricing",
    //   name      : "pricing",
    //   components: { default: PricingPage, header: MainNavbar, footer: MainFooter },
    //   props     : { header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/blog-post",
    //   name      : "blog-post",
    //   components: { default: BlogPost, header: MainNavbar, footer: MainFooter },
    //   props     : { header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/blog-posts",
    //   name      : "blog-posts",
    //   components: { default: BlogPosts, header: MainNavbar },
    //   props     : { header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/ecommerce",
    //   name      : "ecommerce",
    //   components: { default: EcommercePage, header: MainNavbar, footer: MainFooter },
    //   props     : { header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/product",
    //   name      : "product",
    //   components: { default: ProductPage, header: MainNavbar },
    //   props     : { header: { colorOnScroll: 450 } }
    // },
    // {
    //   path      : "/sections",
    //   name      : "sections",
    //   components: { default: Sections, header: MainNavbar, footer: MainFooter },
    //   props     : { footer: { backgroundColor: 'gray' }, header: { colorOnScroll: 0 }}
    // },
    
    // {
    //   path     : "/app",
    //   name     : "app",
    //   meta     : { requiresAuth: true },
    //   components: { default: MainPage, header: AppNavbar, footer: MainFooter },
    //   props     : { footer: { backgroundColor: 'gray' }, header: { colorOnScroll: 0 }}
    //   //redirect : "/app/main"
    // },
    // //children : [
    {
      path      : "/app/main",
      name      : "appMain",
      meta      : {requiresAuth: true},
      components: {default: MainPage, header: AppNavbar, footer: AppFooter},
      props     : {footer: {backgroundColor: 'gray'}, header: {colorOnScroll: 58}}
    },
    {
      path      : "/app/profile",
      name      : "appProfile",
      meta      : {requiresAuth: true},
      components: {default: ProfilePage, header: AppNavbar, footer: AppFooter},
      props     : {header: {colorOnScroll: 58}}
    },
    {
      path      : "/app/meals",
      name      : "appMeals",
      meta      : {requiresAuth: true},
      components: {default: MealsPage, header: AppNavbar, footer: AppFooter},
      props     : {header: {colorOnScroll: 53}}
    },
    {
      path      : "/app/history",
      name      : "appHistory",
      meta      : {requiresAuth: true},
      components: {default: HistoryPage, header: AppNavbar, footer: AppFooter},
      props     : {header: {colorOnScroll: 53}}
    },
    {
      path      : "/app/payments",
      name      : "appPayments",
      meta      : {requiresAuth: true},
      components: {default: AppPaymentsPage, header: AppNavbar, footer: AppFooter},
      props     : {header: {colorOnScroll: 450}}
    },
    {
      path      : "/app/help",
      name      : "appHelp",
      meta      : {requiresAuth: true},
      components: {default: AppHelpPage, header: AppNavbar, footer: AppFooter},
      props     : {header: {colorOnScroll: 58}}
    }
  
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if(to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  },
};

const router = new Router(routeConfig);
router.beforeEach((to, from, next) => {
  
  //console.log( "beforeEach()", JSON.stringify(router.app.$options.data().isAuthenticated ,null,2));
  // won't matter if you bypass this, the api won't hand you any data...
  try {
    if(to.meta !== undefined) {
      if(to.meta.requiresAuth !== undefined) {
        if(to.meta.requiresAuth === true) {
          if(router.app.$options.data().isAuthenticated !== true) {
            next({path: "/login"});
          }
        }
      }
    }
  } catch(err) {
    console.log("oops, caught no meta.requiresAuth", err.message);
  }
  //console.log( "is Authenticated? " , JSON.stringify(to,null,2), JSON.stringify(from ,null,2));
  next();
});

export default router;
