<template>
  <div class="mt-4" id="printable-calendar">
    <div class="row position-relative">
      <div class="col-12 text-left pl-0 pr-1">
        <h2 class="font-weight-bold calendar-heading d-inline-flex">{{calendarItems.title}}</h2>
        <div class="calendar-action-buttons">
          <div class="pull-left"><h2 class="font-weight-bold calendar-month-year d-inline-flex">{{currentMonthName}}<span>{{selectedYear}}</span></h2></div>
          <b-button class="non-printable prev-button" @click="selectedMonthIncrementDecrement(false)" :disabled="calendarStartMonth === selectedMonth" variant="light" v-if="config.enablePrevButton && !(calendarStartMonth === calendarEndMonth)">&#60;</b-button>
          <b-button class="non-printable next-button " @click="selectedMonthIncrementDecrement(true)" :disabled="calendarEndMonth === selectedMonth" variant="light" v-if="config.enableNextButton && !(calendarStartMonth === calendarEndMonth)">&#62;</b-button>
          <div class="non-printable print-button"  @click="printCalendar('printable-calendar')" v-if="config.enablePrintCalendar"><i class="fa fa-print" ></i></div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-2 calendar-days calendar-days-bottom" :class="'calendar-'+day.toLowerCase()" v-for="(day, index) in days" :key="'days'+index">
            {{day}}
          </div>
        </div>
      </div>
      <div class="col-2 calendar" :class="index === 1 ? 'day-container-sunday' : ''" v-for="index in emptyDays" :key="'emp'+index">
        &nbsp;
      </div>
      <div class="col-2 calendar calendar-body" :class="dayContainerClass(index)" @click="selectDay(index)" v-for="(items, index) in filterData" :key="index">
        <div class="calendar-date">{{index+1}}</div>
        <div class="calendar-text">
          {{items.title}}
          <span v-if="config.isShowMenuDescription" class="item-description">{{items.description}}</span>
          <div :class="!items.title && index === 0 ? 'no-border' : ''" v-for="(item, index) in items.choices" :key="index">
            {{item.title}}<span class="item-price" v-if="config.isShowItemPrice"> {{currency(item.price[0])}}</span>
            <span v-if="config.isShowItemDescription" class="item-description">{{item.description}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
  import Moment from "moment-timezone"
  import Currency from "currency.js"

export default {
  name: 'orderMonthlyCalendar',
  components: {
  },
  props: {
    config: {type: Object,default: () => {} },
    calendarItems: { type: Object, default: () => {} }
  },
  data() {
    return {
      TZ                : this.$root.account.tenant.TZ,
      selectedMonth     : 1,                                                                                // Moment().month() + 1, // current Month
      selectedYear      : 2020,                                                                             // Moment().year(), //current year /
      days              : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      calendarEndMonth  : (Moment(this.calendarItems.calendarEndDate).tz(this.$root.account.tenant.TZ).month()+1),
      calendarStartMonth: (Moment(this.calendarItems.calendarStartDate).tz(this.$root.account.tenant.TZ).month()+1)
    }
  },
  mounted() {
    console.log( "TZ" , this.$root.account.tenant.TZ);
    this.selectedMonth = Moment(this.calendarItems.calendarStartDate).tz(this.$root.account.tenant.TZ).month() + 1;
    this.selectedYear = Moment(this.calendarItems.calendarStartDate).tz(this.$root.account.tenant.TZ).year();
  },
  computed: {
    filterData() {
      let self= this
      let days = Moment(`${self.selectedYear}-${self.selectedMonth}`, "YYYY-MM").tz(this.$root.account.tenant.TZ).daysInMonth()
      let data = []
      var index = 0
      for (var i = 1; i <= days; i++) {
        if( index < self.calendarItems.menu.length && i == self.convertDate(self.calendarItems.menu[index].menuDate)){
          data.push(self.calendarItems.menu[index])
          index++
        } else {
          data.push({})
        }
      }
      return data
    },
    currentMonthName(){
      return Moment(`${this.selectedMonth}`, "MM").tz(this.$root.account.tenant.TZ).format('MMMM')
    },
    emptyDays(){
      return Moment(`${this.selectedYear}-${this.selectedMonth}-01`, "YYYY-MM-DD").tz(this.$root.account.tenant.TZ).day()
    }
  },
  methods: {
    currency(c) {
      return Currency(c);
    },
    dayContainerClass(index) {
      let cls = 'day-container-' + this.days[((this.emptyDays + index) % 7)].toLowerCase();
      return ((this.emptyDays + index + 1) % 7 == 0 || this.filterData.length == (index+1) ? 'calendar-right-border' : '') + ' ' + cls
    },
    convertDate(input) {
      return Moment(input).tz(this.$root.account.tenant.TZ).format('D')
    },
    selectedMonthIncrementDecrement(incrementDecrement){
      if(incrementDecrement){
        this.selectedMonth += 1
      } else{
        this.selectedMonth -= 1
      }
      if(this.selectedMonth === 13){
        this.selectedMonth = 1
        this.selectedYear += 1
      }
      if(this.selectedMonth === 0){
        this.selectedMonth = 12
        this.selectedYear -= 1
      }
      this.$emit('dateChanged', { year: this.selectedYear, month: this.selectedMonth })
    },
    selectDay(day) {
      this.$emit('click', {year:this.selectedYear, month:this.selectedMonth, day: day+1 });
    },
    printCalendar(divName) {
      this.$emit("print");
      window.print();

    }

  }
}
</script>
<style lang="scss" scoped>
  .calendar {
    padding: 0 !important;
    border-left: 1px solid #ccc !important;
    border-bottom: 1px solid #ccc !important;
    width: 14.2% !important;
    flex: 0 0 14.2% !important;
    max-width: 14.2% !important;
    .calendar-date{
      float: left;
      color: red;
      font-size: 30px;
      padding: 0px 12px;
      padding-bottom: 0;
      line-height: 20px;
      padding-top: 10px;
    }
    .calendar-text{
      padding: 10px;
      text-align: right;
      font-size: 14px;
      padding-top: 20px;
      div {
        border-top: 1px solid #ddd;
      }
      .item-price {
        font-size:.7em;
      }
      .item-description {
        display: block;
        white-space:pre-wrap;
        font-size: 11px;
        color: #999;
      }
      .no-border {
        border: none ;
      }
    }
    .calendar-no-data{
      min-height: 165px;
    }
  }
  .calendar-right-border{
    border-right: 1px solid #ccc ;
  }
  .calendar-body {
    cursor:pointer;
  }
  .calendar-days {
    font-weight: 600;
    width: 14.2% ;
    flex: 0 0 14.2% ;
    text-align:center;
    padding: 8px 0;
  }
  .calendar-days-bottom {
    border-bottom: 1px solid #ccc !important;
  }
  .calendar-heading{
      font-size: 36px;
      color: #2980B9;
      span {
        font-size: 24px;
      }
  }
  .empty-days-calendar{
    width: 14.2% !important;
    flex: 0 0 14.2% !important;
    border-bottom: 1px solid #ccc !important;
  }
  .calendar-sunday {
      background: #2980B9;
      color: #fff;
  }
  .calendar-monday {
      background: #17A187;
      color: #fff;
  }
  .calendar-tuesday {
      background: #94b84c;
      color: #fff;
  }
  .calendar-wednesday {
      background: #f1a00f;
      color: #fff;
  }

  .calendar-thursday {
      background: #c23a2b;
      color: #fff;
  }
  .calendar-friday {
      background: #55677B;
      color: #fff;
  }
  .calendar-saturday {
      background: #2980B9;
      color: #fff;
  }
  .calendar-action-buttons {
    float: right;
    button {
      background: #2980B9 !important;
      color: #fff !important;
    }
    .next-button{
      margin-right: 10px;
    }
    .pull-left {
      float: left;
    }
    .print-button {
      font-size:2em;
      float:right;
      margin-right:1em;
      color: #2980B9;
      cursor:pointer;
    }
    .calendar-month-year{
      font-size: 36px;
      color: #2980B9;
      margin: 0;
      display: inline-flex;
      margin-bottom: 0px;
      margin-right: 10px;
      span {
        font-size: 23px;
      }
    }
  }


  .calendar.day-container-sunday {
    background: #f5f5f5;
    .calendar-date {
      color:#2980B9;
    }
  }

  .calendar.day-container-monday {
    background: #fff;
    .calendar-date {
      color:#17A187;
    }
  }

  .calendar.day-container-tuesday {
    background: #fff;
    .calendar-date {
      color:#94b84c;
    }
  }

  .calendar.day-container-wednesday {
    background: #fff;
    .calendar-date {
      color:#f1a00f;
    }
  }

  .calendar.day-container-thursday {
    background: #fff;
    .calendar-date {
      color:#c23a2b;
    }
  }

  .calendar.day-container-friday  {
    background: #fff;
    .calendar-date {
      color:#55677B;
    }
  }

  .calendar.day-container-saturday {
    background: #f5f5f5;
    .calendar-date {
      color:#2980B9;
    }
  }

  @media print {

    body {
      -webkit-print-color-adjust: exact important;
      margin:0;
      padding:0;
    }
    /*.logo {
      width: 300px !important;
      max-width: 300px !important;
    }*/

    .non-printable, noprint, #nav {
      display: none;
    }

    .container {
      /*width: 100%;*/
      /*max-width: 100%;*/
    }
    .calendar.day-container-sunday {
      background: #fff;
      .calendar-date {
        color:#2980B9;
      }
    }
    .calendar.day-container-saturday {
      background: #fff;
      .calendar-date {
        color:#2980B9;
      }
    }
  }


   /*style sheet for "letter" printing*/
  @media print and (width: 8.5in) and (height: 11in) {
    @page {
        margin: .25in;
    }
  }
</style>
