<template>
  <div class="page-header signup-page section-image">
    <div class="page-header-image"
         style="background-image: url('img/back-to-school-start-MACVKugZ8WQ.jpg')">
    </div>
    <div class="content">
      <div class="container">
        <notifications></notifications>
        <div class="row" style="padding-top:72px;">
          <div class="col-md-4 ml-auto mr-auto">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons media-2_sound-wave"></i>
              </div>
              <div class="description">
                <h5 class="info-title">First, we need your email address</h5>
                <p class="description">
                  After you supply your email address, select "Send Me a Reset Token". This is similar how you received your registration confirmation code.
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons media-1_button-pause"></i>
              </div>
              <div class="description">
                <h5 class="info-title">Check your email</h5>
                <p class="description">
                  When you receive your code, select "I Have a Token" if the token field is not visible, enter your token, and we will show you a field to enter your new password. Easy Peasy.
                </p>
              </div>
            </div>

          </div>
          <div class="col-md-4 mr-auto">
            <div class="card card-signup">
              <div class="card-body">
                <h4 class="card-title text-center">{{pageTitle}}</h4>
                <form v-if="!askPassword">
                  <fg-input addon-left-icon="now-ui-icons ui-1_email-85"
                            v-model="form.email"
                            autocomplete="username"
                            @keydown.enter="requestToken(form.email)"
                            placeholder="Your Email">
                  </fg-input>
                </form>
                <template v-if="!askPassword && form.email.indexOf('@')>1">
                  <div class="card-footer text-center" style="margin-left:.25em;margin-right:.55em;">
                    <a @click="requestToken(form.email)" class="btn btn-success btn-round btn-lg btn-block">Send Me a Reset Token</a>
                    <a @click="askPassword=true" class="btn btn-warning btn-round btn-lg btn-block">I Have A Token</a>
                  </div>
                </template>
                <form v-if="askPassword">
                  <fg-input class="no-border input-lg"
                    addon-left-icon="now-ui-icons ui-1_email-85"
                            v-model="form.email"
                            readonly
                            autocomplete="username"
                            placeholder="Your Email">
                  </fg-input>
                  <fg-input class="no-border input-lg"
                            ref="resetCodeControl"
                            addon-left-icon="now-ui-icons users_circle-08"
                            v-model="form.resetCode"
                            :style="form.resetCode.length===7?'border-radius:5em; background-color:#7fb847;':''"
                            type="text"
                            placeholder="Registration Code (check email)">
                  </fg-input>
                  <fg-input v-if="form.resetCode.length===7" class="no-border input-lg"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                            v-model="form.password"
                            type="password"
                            autocomplete="new-password"
                            :style="passwordMatch"
                            placeholder="New Password">
                  </fg-input>

                  <fg-input class="no-border input-lg"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                            v-model="form.confirmPassword"
                            v-if="form.password.length>5 && form.resetCode.length===7"
                            type="password"
                            autocomplete="confirm-password"
                            @keydown.enter="resetPassword(form.email, form.resetCode, form.password)"
                            :style="passwordMatch"
                            placeholder="Confirm Password">
                  </fg-input>
                </form>
                <template v-if="askPassword && form.resetCode>'' && form.email.indexOf('@')>1 && form.password.length>0 && form.password === form.confirmPassword && form.resetCode.length===7">
                  <div class="card-footer text-center" style="margin-left:.25em;margin-right:.55em;">
                    <a @click="resetPassword(form.email, form.resetCode, form.password )" class="btn btn-warning btn-round btn-lg btn-block">Reset My Password</a>
                  </div>
                </template>
                <template v-if="askPassword">
                  <div class="card-footer text-center" style="margin-left:.25em;margin-right:.55em;">
                    <a @click="askPassword=false" class="btn btn-primary btn-round btn-lg btn-block">No Thanks, Cancel</a>
                  </div>
                </template>
                <b-alert variant="danger" :show="showPWMessage" style="width:80%;margin:auto;border-radius:3em;background-color:#F96332;">Passwords Do Not Match?</b-alert>

                <div class="card-footer text-center" style="margin-left:.25em;margin-right:1em;">
                  <a v-if="form.agree  && (form.password===form.confirmPassword)" @click="signup()" class="btn btn-primary btn-round btn-lg btn-block">I Agree, Register Me!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
  import { Button, Card, Checkbox, FormGroupInput } from '@/components';
  import MainFooter                                 from '@/layout/MainFooter';
  import AuthenticationService                      from "@/services/AuthenticationService";
  
  export default {
    name      : 'forgot-password-page',
    bodyClass : 'signup-page',
    components: {
      Card,
      MainFooter,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput
    },
    data() {
      return {
        schoolCodeError: "",
        askPassword    : false,
        form           : {
          resetCode      : "",
          email          : '',
          password       : '',
          confirmPassword: ''
        }
      }
    },
    computed  : {
      pageTitle() {
        if( this.askPassword ) {
          return "Enter New Password";
        } else {
          return "Forgot Password";
        }
      },
      passwordMatch() {
        if(this.form.password.length > 0 && this.form.confirmPassword.length > 0) {
          if(this.form.password === this.form.confirmPassword) {
            return "border-radius:5em; background-color:#7fb847;";
          } else {
            return "border-radius:5em; background-color:#F96332;";  // F96332
          }
        }
        return "";
      },
      showPWMessage() {
        if(this.form.password.length > 0 && this.form.confirmPassword.length > 0) {
          if(this.form.password === this.form.confirmPassword) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
    },
    methods   : {
      async resetPassword(email, resetCode, newPassword) {

        if( email === "" ) {
          return await this.$root.alert( "Please enter a valid email address");
        }

        if( resetCode.length !== 7 ) {
          return await this.$root.alert( "Please enter a valid token" );
        }

        try {

          let response = await AuthenticationService.resetPassword( email.toLowerCase(), resetCode , newPassword );
          this.$notify(
            {
              message        : response.data.message,
              icon           : 'fa fa-exclamation',
              horizontalAlign: 'center',
              verticalAlign  : 'top',
              type           : "success"
            })

          setTimeout( this.$router.push( "login" ), 2000 );

        } catch( error ) {
          console.log(error.response);
          if( error.response ) {
            this.$notify(
              {
                message        : error.response.data.message,
                icon           : 'fa fa-exclamation',
                horizontalAlign: 'center',
                verticalAlign  : 'top',
                type           : "danger"
              })
          }
        }

      },
      async requestToken(email) {

        if(email === "") {
          return await this.$root.alert("Please enter a valid email address");
        }

        try {
          this.$notify(
            {
              message        : "Password Reset Request Sent",
              icon           : 'fa fa-exclamation',
              horizontalAlign: 'center',
              verticalAlign  : 'top',
              type           : "success"
            })
          let response = await AuthenticationService.forgotPassword(email.toLowerCase());
          this.$notify(
            {
              message        : "Please Check Your Email For Your Token",
              icon           : 'fa fa-exclamation',
              horizontalAlign: 'center',
              verticalAlign  : 'top',
              type           : "success"
            })

          //console.log(JSON.stringify(response.data, null, 2));
          this.askPassword = true;
        } catch(error) {
          console.log(error.response);
          if( error.response ) {
            this.$notify(
              {
                message        : error.response.data.message,
                icon           : 'fa fa-exclamation',
                horizontalAlign: 'center',
                verticalAlign  : 'top',
                type           : "danger"
              })
          }
        }
      }
    }
  }
</script>
<style>
</style>
