<template>
  <div>

    <div class="dh-receipt" :style="receiptStyle">
      <h3 class="dh-receipt-title" :style="h3style">{{title}}<span v-if="orderLabel.length>0" class="dh-order-cancel" @click="cancelOrder()"><i class="fa fa-trash"></i></span></h3>
      <div class="dh-receipt-body" :style="receiptBodyStyle">

        <table class="table table-hover">
          <tbody>
          <tr v-for="(item,itemIndex) in order.items" :key="itemIndex">
            <td v-if="!disabled" class="show-hand" @click="deleteItem(item.itemNumber)"><i class="fa fa-trash"></i></td>
            <td>{{item.qty}}</td>
            <td>
              <div style="font-weight:800">{{item.title}}</div>
              <div v-if="item.description.length>0" style="vertical-align:bottom;white-space:pre-line;padding-left:1em;">{{item.description}}</div>
            </td>
            <td>{{currency(item.price).multiply( item.qty) }}</td>
          </tr>
          </tbody>
        </table>

      </div>
      <div class="dh-receipt-footer" :style="receiptFooterStyle">
        <div class="dh-summary-total"><span>SubTotal</span><span>{{currency(order.subTotal)}}</span></div>
        <div class="dh-summary-total"><span>Tax</span><span>{{currency(order.tax)}}</span></div>
        <div class="dh-summary-total-bold"><span>Total</span><span>{{currency(order.total)}}</span></div>
        <div v-if="order.totalItems" class="dh-counter" :style="textColorStyle"><span> Item{{(order.totalItems!=1)?'s':''}}</span><span>{{parseInt(order.totalItems)}}</span></div>
        <div v-if="orderLabel.length>0" class="dh-order-button">
          <button v-if="disabled" class="btn btn-fill" style="max-width:160px;white-space:normal;word-wrap:break-word;border-radius:.75em;" @click="enableOrder()">
            {{enableOrderLabel}}
          </button>
          <button v-if="!disabled && order.items.length>0" class="btn btn-fill" style="max-width:160px;white-space:normal;word-wrap:break-word;border-radius:.75em;background:#7fb847;font-weight:700;" @click="placeOrder()">{{orderLabel}}</button>
        </div>
      </div>
    </div>

  </div>

</template>
<script>

  import { CalendarDay } from '@/components';
  import Currency        from "currency.js";
  import Moment          from "moment";
  
  export default {
    
    props     : {
      orderDate: {type: [ Date, String, Object ], default: null},
      title    : {type: String, default: ""},

      orderLabel      : {type: String, default: ""},
      enableOrderLabel: {type: String, default: "Edit Order"},
      disabled        : {type: Boolean, default: false},
      disabledColor   : {type: String, default: "#ccc"},
      color           : {type: String, default: "#e3a03b"},
      textColor       : {type: String, default: "white"},
      receiptTextColor: {type: String, default: "black"},
      fontSize        : {type: String, default: "medium"},
      borderFontSize  : {type: String, default: "2em"},

      order: {type: Object, default: () => {}},

    },
    components: {
      CalendarDay,
      Moment
    },
    watch     : {
      orderDate(newVal, oldval) {
        this.makeDateNames();
      }
    },
    computed  : {
      receiptStyle() {
        return "background: " + this.color +
               ";border: 2px solid " + this.color + ";";
      },
      receiptBodyStyle() {
        let style = "color:" + this.receiptTextColor + ";";
        if(this.disabled) {
          style += "background:" + this.disabledColor + ";";
        }
        return style;
      },
      h3style() {
        return "color: " + this.textColor + ";";
      },
      textColorStyle() {
        return "color:" + this.textColor + ";";
      },
      receiptFooterStyle() {
        return "color:" + this.textColor + ";"
      }

    },
    data() {
      return {
        monthName: "",
        dayName  : "",
        dayNumber: 0,
        selected : -1
      }
    },
    created() {
      this.makeDateNames();
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      newTransaction() {
        this.item        = null;
        this.order.items = [];
      },
      placeOrder() {
        this.$emit("order", this.order);
      },
      cancelOrder() {
        this.$emit("cancel", this.order);
      },
      enableOrder() {
        this.$emit("enable", this.order);
      },
      deleteItem(itemNumber) {
        if(this.order.items.length === 0) {
          return;
        }
        this.$emit("delete", itemNumber);
      },
      defaultDayName() {
        return this.moment(this.orderDate).format('dddd');
      },
      makeDateNames() {
        this.dayNumber = this.moment(this.orderDate).format('D');
        this.monthName = this.moment(this.orderDate).format('MMMM');

        if(this.title !== "") {
          this.borderTitle = this.title;
        } else {
          this.borderTitle = this.defaultDayName();
        }
      }
    }
  }
</script>
<style scoped lang="scss">

  .dh-receipt-frame {
  }

  .dh-receipt {
    position: relative;
    float: left;
    margin: 1em;
    border-radius: 1em;
    padding: 3px;
    height: 600px;
    width: 400px;
    min-height: 600px;
    min-width: 300px;
  }

  .dh-receipt-title {
    text-align: center;
    border-radius: .5em;
    margin: 0;
  }

  .dh-receipt-body {
    position: absolute;
    border-radius: .75em;
    margin-top: 4px;
    height: 464px;
    width: 390px;
    min-height: 464px;
    min-width: 344px;
    padding: 2px;
    display: table-cell;
    overflow-y: auto;
    background: white;
  }


  .dh-counter {
    left: -18px;
    bottom: 0px;

    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 1em;

    span:first-child {
      position: absolute;
      bottom: 4px;
      left: 14px;
      font-size: 1em;
      vertical-align: bottom;
      text-align: center;
    }

    span:nth-child(2) {
      position: absolute;
      bottom: 14px;
      left: 25px;
      font-size: 3em;
      font-weight: 900;
    }

  }

  .dh-order-cancel {
    cursor: pointer;
    float: right;
    margin-right: .3em;
    font-weight: 400;
    color: white;
  }


  .dh-order-button {
    position: absolute;
    border-radius: .75em;
    text-align: center;

    top: 0;
    left: -185px;
    width: 170px;
    height: 80px;
  }


  .dh-summary-total {
    line-height:.85em;
    width: 180px;
    display: inline-block;

    span:first-child {
      display: table-cell;
      width: 110px;
      text-align: right;
    }

    span:nth-child(2) {
      width: 70px;
      display: table-cell;
      text-align: right;
      font-weight: 900;
    }
  }

  .dh-summary-total-bold {
    width: 180px;
    display: inline-block;

    span:first-child {
      display: table-cell;
      width: 110px;
      text-align: right;
    }

    span:nth-child(2) {
      width: 70px;
      font-size: 1.25em;
      display: table-cell;
      text-align: right;
      font-weight: 900;
    }
  }


  .dh-receipt-footer {
    position: absolute;
    bottom: 0;
    left: 192px;
    margin-bottom: 4px;
    border-radius: .75em;
    padding: 4px;
    height: 80px;
    width: 200px;

  }


</style>


