<template>
  <div>
    <div class="dh-frame" :style="frameStyle" @click="frameClicked">
      <h3 class="dh-frame-title" :style="h3style">{{borderTitle}}</h3>
      <h4 class="dh-day-number">{{ dayNumber }}</h4>
      <template>
        <div v-if="choices" class="dh-body" :style="bodyStyle">
          <div v-for="(choice,cindex) in choices" :key="cindex">
            <div class="dh-category-title shadow" :style="bodySeperatorStyle">{{choice.title}}</div>
            <ul v-for="(item,index) in choice.items" :key="index" class="dh-item" @click="addItemToOrder(item,choice.usePriceLevel)">
              <li class="dh-item-title">
                {{item.title}}
                <!-- <div  style="float:right;margin-right:1em;">{{item.qty}}</div> -->
              </li>
              <li v-if="item.description" class="dh-item-description">
                {{item.description}}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>

</template>
<script>

  import { CalendarDay } from '@/components';
  import Currency        from "currency.js";
  import Moment          from "moment";

  export default {

    props     : {
      orderDate: {type: [ Date, String, Object ], default: null},
      title    : {type: String, default: ""},

      color             : {type: String, default: "#e3a03b"},
      textColor         : {type: String, default: "white"},
      seperatorTextColor: {type: String, default: "black"},
      seperatorColor    : {type: String, default: "gray"},
      fontSize          : {type: String, default: "medium"},
      borderFontSize    : {type: String, default: "2em"},
      choices           : {type: Array, default: () => []},

      priceLevel: {type: Number, default: 0},
      fnGetOrder: {type: Function, default: null},
      disabled  : {type: Boolean, default: false }

    },
    components: {
      CalendarDay
    },
    watch     : {
      orderDate(newVal, oldval) {
        this.makeDateNames();
      }
    },
    computed  : {
      frameStyle() {
        let style = "background: " + this.color +
                    ";border-bottom: 2x solid " + this.color +
                    ";border-top: 2px solid " + this.color +
                    ";border-left: 50px solid " + this.color + ";";
        return style;
      },
      h3style() {
        return "color: " + this.textColor + ";";
      },
      textColorStyle() {
        return "color:" + this.textColor + ";";
      },
      bodyStyle() {
        let style = "";
        if( this.disabled === true ) {
          style = "background:#aaa;";
        }
        return style;
      },
      bodySeperatorStyle() {
        return "background:" + this.color +
               ";color:" + this.textColor + ";";
      },
      footerStyle() {
        return "";
      }
    },
    data() {
      return {
        monthName  : "",
        dayName    : "",
        dayNumber  : 0,
        borderTitle: this.defaultDayName()
      }
    },
    mounted() {
      this.makeDateNames();
      if(this.title !== "") {
        this.borderTitle = this.title;
      }
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      addItem(item) {
        if(this.disabled !== true ) {
          this.$emit("add", item);
        }
      },
      addItemToOrder(item, usePriceLevel, abc) {
        let priceLevel;
        if(usePriceLevel) {
          priceLevel = this.priceLevel;
        } else {
          priceLevel = 0;
        }

        let oItem = {
          itemCategory: item.category,
          orderDate   : this.orderDate,
          itemNumber  : item.itemNumber,
          priceLevel  : priceLevel,
          qty         : 1,
          title       : item.title,
          description : item.description,
          price       : item.price[ priceLevel ],
          minChoice   : item.minChoice || 0,
          maxChoice   : item.maxChoice || 3
        }
        this.addItem(oItem);
      },
      defaultDayName() {
        return this.moment(this.orderDate).format('dddd');
      },
      makeDateNames() {
        this.dayNumber = this.moment(this.orderDate).format('D');
        this.monthName = this.moment(this.orderDate).format('MMMM');

        if(this.title !== "") {
          this.borderTitle = this.title;
        } else {
          this.borderTitle = this.defaultDayName();
        }
      },
      frameClicked() {
        this.$emit("click", this.title);
      }
    }
  }
</script>
<style scoped lang="scss">

  .scale-out-center {
    -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}


  .dh-frame {
    float: left;
    margin: 1em;
    border-radius: 1em;
    padding: 3px;
    height: 600px;
    width: 398px;
    min-height: 500px;
    min-width: 300px;
  }

  .dh-frame-title {
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -25px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
  }

  .dh-day-number {
    position: absolute;
    height: 48px;
    width: 48px;
    border: 3px solid white;
    top: -12px;
    left: -48px;
    border-radius: 50%;
    background: #ff0202;
    color: white;
    text-align: center;
    font-size: 2em;
    font-weight: 800;
  }

  .dh-body {
    position: absolute;
    border-radius: .75em;
    top: 2px;
    left: 2px;
    height: 592px;
    width: 342px;

    background: #eee;
    vertical-align: middle;
    display: table-cell;
    padding: 2px;
    overflow-y: auto;
  }

  .dh-item {
    cursor: pointer;
    padding-left: 1%;
    padding-right: 1%;
    margin: 0;
  }

  .dh-item:hover {
    background: #ddd;
  }

  .dh-item-title {
    white-space: pre-line;
    text-align: center;
    font-weight: 500;
    font-size: 1em;
    line-height: .75em;
    padding-bottom: .5em;

  }

  .dh-item-description {
    text-overflow: ellipsis;
    width: 100%;
    white-space: pre-line;
    text-align: center;
    font-size: .90em;
    line-height: 1em;
    // margin-top: -4%;
    margin-top:-.1em;
    padding-bottom: 1em;
  }

  .dh-category-title {
    display: block;
    border-radius: .5em;
    text-align: center;
    font-weight: 600;
    font-size: 1.25em;
    padding: 4px;
    margin-bottom: .5em;
  }


  .dh-counter {
    left: -18px;
    bottom: 0px;

    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 1em;

    span:first-child {
      position: absolute;
      bottom: 4px;
      left: 14px;
      font-size: 1em;
      vertical-align: bottom;
      text-align: center;
    }

    span:nth-child(2) {
      position: absolute;
      bottom: 14px;
      left: 25px;
      font-size: 3em;
      font-weight: 900;
    }

  }


</style>


