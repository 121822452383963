<template>
  <div>
    <div class="dh-frame" :style="frameStyle" @click="frameClicked">
      <h3 class="dh-frame-title" :style="h3style">{{borderTitle}}</h3>
      <h4>{{ dayNumber }}</h4>
      <template>
        <div class="dh-body">
          <ul v-for="(item,index) in items" :key="index" @click="addItemToOrder(item)">
            <li>
              {{item.title}}
            </li>
            <li>
              {{item.description}}
            </li>
          </ul>
        </div>
      </template>
      <template>
        <div v-if="extras" class="dh-extras" :style="extrasStyle">
          <ul>
            <li v-for="(item,index) in extras" :key="index" @click="addExtraToOrder( item )">
              {{item.title}}
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>

</template>
<script>

  import { CalendarDay } from '@/components';
  import Currency        from "currency.js";
  import Moment          from "moment";
  
  export default {
    
    props     : {
      orderDate: {type: [ Date, String, Object ], default: null},
      title    : {type: String, default: ""},

      orderLabel      : {type: String, default: "Place Order"},
      // width         : {type: String, default: "300"},
      // height        : {type: String, default: "500"},
      color           : {type: String, default: "#e3a03b"},
      textColor       : {type: String, default: "white"},
      receiptTextColor: {type: String, default: "black"},
      extrasTextColor : {type: String, default: "black"},
      fontSize        : {type: String, default: "medium"},
      // textAlign     : {type: String, default: "left"},
      borderFontSize  : {type: String, default: "2em"},

      priceLevel: {type: Number, default: 0},
      taxRate   : {type: Number, default: 0},
      items     : {type: Array, default: () => []},
      extras    : {type: Array, default: () => []},

      fnGetOrder: {type: Function, default: null}

    },
    components: {
      CalendarDay,
      Moment
    },
    watch     : {
      orderDate(newVal, oldval) {
        this.makeDateNames();
      },
      priceLevel(newVal, oldVal) {
        this.order.priceLevel = newVal;
      }
    },
    computed  : {
      frameStyle() {
        let style = "background: " + this.color +
                    ";border-bottom: 2x solid " + this.color +
                    ";border-top: 2px solid " + this.color +
                    ";border-left: 50px solid " + this.color + ";";
        return style;
      },
      // receiptStyle() {
      //   return "background: " + this.color +
      //          ";border: 2px solid " + this.color + ";";
      // },
      // receiptBodyStyle() {
      //   return "color:" + this.receiptTextColor + ";";
      // },
      h3style() {
        return "color: " + this.textColor + ";";
      },
      textColorStyle() {
        return "color:" + this.textColor + ";";
      },
      bodyStyle() {
        let style = "";
        return style;
      },
      extrasStyle() {
        let style = "color:" + this.extrasTextColor + ";";
        return style;
      },
      footerStyle() {
        return "";
      },
      // receiptFooterStyle() {
      //   return "color:" + this.textColor + ";"
      // },
      total() {
        return this.menuItem.qty * this.menuItem.price;
      }
    },
    data() {
      return {
        monthName  : "",
        dayName    : "",
        dayNumber  : 0,
        borderTitle: this.defaultDayName(),
        order      : {
          orderDate : null,
          subTotal  : 0,
          tax       : 0,
          total     : 0,
          totalItems: 0,
          priceLevel: 0,
          items     : []
        },
        selected   : -1
      }
    },
    mounted() {
      this.makeDateNames();
      if(this.title !== "") {
        this.borderTitle = this.title;
      }
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      newTransaction() {
        this.item        = null;
        this.order.items = [];
        this.calculateOrderTotal();
      },
      placeOrder() {
        // todo: emit the order!
        this.$emit("order", this.order);
        this.newTransaction()
      },

      addItem(item) {
        this.$emit("add", item);
      },
      addExtraToOrder(item) {
        let oItem = {itemType: "extra", orderDate: this.orderDate, itemNumber: item.itemNumber, priceLevel: 0, qty: 1, title: item.title, description: item.description, price: item.price[ 0 ]}
        this.addItem(oItem, 1)
      },
      addItemToOrder(item) {
        let oItem = {itemType: "meal", orderDate: this.orderDate, itemNumber: item.itemNumber, priceLevel: this.priceLevel, qty: 1, title: item.title, description: item.description, price: item.price[ this.priceLevel ]}
        this.addItem(oItem);
      },
      defaultDayName() {
        return this.moment(this.orderDate).format('dddd');
      },
      makeDateNames() {
        this.dayNumber = this.moment(this.orderDate).format('D');
        this.monthName = this.moment(this.orderDate).format('MMMM');

        if(this.title !== "") {
          this.borderTitle = this.title;
        } else {
          this.borderTitle = this.defaultDayName();
        }

      },
      frameClicked() {
        this.$emit("click", this.title);
      }

    }
    
  }
</script>
<style scoped lang="scss">

  .dh-frame {
    float: left;
    margin: 1em;
    border-radius: 1em;
    padding: 3px;
    height: 600px;
    width: 548px;
    min-height: 500px;
    min-width: 300px;

    background: salmon;
  }

  .dh-frame-title {
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -25px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
  }

  .dh-frame h4 {
    position: absolute;
    height: 36px;
    width: 36px;
    border: 3px solid white;
    top: -12px;
    left: -42px;
    border-radius: 50%;
    background: gray;
    color: white;
    text-align: center;
    font-size: 1.4em;
    font-weight: 800;
  }


  .dh-frame ul {
    vertical-align: middle;
    list-style: none;
    padding-left: 0;
    /*padding-top: .7em;*/
    /*padding-bottom: .3em;*/
  }

  .dh-body {
    position: absolute;
    border-radius: .75em;
    top: 2px;
    left: 2px;
    height: 592px;
    width: 244px;

    background: #eee;
    vertical-align: middle;
    display: table-cell;
    padding: 2px;
    overflow-y: auto;

  }

  .dh-body ul {
    cursor: pointer;
  }

  .dh-body li {
    white-space: pre-line;
    text-align: center;
  }

  .dh-body ul:hover {
    background: #ddd;
  }

  .dh-body li:first-child {
    font-weight: 900;
    line-height: .9em;
    margin-bottom: -7%;
    margin-top: -5%;
  }

  .dh-body li:nth-child(2) {
    text-overflow: ellipsis;
  }

  .dh-extras {
    background: white;
    position: absolute;
    border-radius: .75em;
    padding: 3px;
    top: 2px;
    left: 250px;
    height: 592px;
    width: 244px;
    vertical-align: middle;
    display: table-cell;
    overflow-y: auto;
  }

  .dh-extras li {
    cursor: pointer;
    text-align: center;
    line-height: 2em;
  }

  .dh-extras li:hover {
    font-weight: 900;
    background: #eee;
  }


  .dh-counter {
    left: -18px;
    bottom: 0px;

    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 1em;

    span:first-child {
      position: absolute;
      bottom: 4px;
      left: 14px;
      font-size: 1em;
      vertical-align: bottom;
      text-align: center;
    }

    span:nth-child(2) {
      position: absolute;
      bottom: 14px;
      left: 25px;
      font-size: 3em;
      font-weight: 900;
    }

  }


</style>


