<template>
  <footer id="app1Footer" class="footer"
          :class="{[`footer-${type}`]: type}"
          :data-background-color="backgroundColor">
    <div class="container">
      <nav>
        <ul>
          <li>
            <b-link :href="$root.portal.aboutUrl" target="_blank">
              About {{$root.account.tenant.tenantName}}
            </b-link>
          </li>
          <li>
            <a href="license">
              License
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        {{$root.appTitle}} V{{$root.appVersion}} Coded With <i class="fa fa-heart" style="color:red;"></i> by Us for You over at
        <a href="https://www.newcare.international" target="_blank">Newcare International</a>, &copy; 2018-{{year}}, All Rights Reserved.
        <!--                Coded by <a href="https://www.mySchool.cafe/team" target="_blank">our amazing team</a>-->
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type           : String
    },
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>
