<template>
    <div class="wrapper landing-page">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('/img/pexels-photo-220320.jpeg')">
            </parallax>
            <div class="content-center">
                <h1 class="title">App Payments</h1>
                <!-- <div class="text-center">
                    <a href="#pablo" class="btn btn-primary btn-icon  btn-round">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                    <a href="#pablo" class="btn btn-primary btn-icon btn-round">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="#pablo" class="btn btn-primary btn-icon btn-round">
                        <i class="fab fa-google-plus"></i>
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput, Tabs, TabPane } from '@/components';

  export default {
    name: 'app-about-page',
    bodyClass: 'landing-page',
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
       
      }
    }
  }
</script>
<style>
</style>
