import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import VueAnalytics from 'vue-analytics';
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
//import Api from "@/services/Api";

import VueNotify from 'vue-notifyjs';
import 'vue-notifyjs/themes/now-ui.css';
//plugins
import NowUIKit from './plugins/now-ui-kit';
import VueGlide from "vue-glide-js";
import 'vue-glide-js/dist/vue-glide.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import {version as version} from '../package.json';

Vue.config.productionTip = false;

Vue.use(VueGlide);
Vue.use(VueNotify);
Vue.use(NowUIKit);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.use(VueAnalytics, {
  id          : "UA-128966409-5",   // mySchool.cafe 
  router,
  autoTracking: {
    pageviewTemplate(route) {
      let o = {};
      if(typeof route.matched != 'undefined') {       // wjs: 03-Nov-17 :: Figure if matched is present, it's an array with at least 1 element
        o.title = route.matched[ 0 ].name;            //   seems to be the same as route.name, but for consistency used the matched name
        if(route.matched[ 0 ].path != '*') {          // let's capture the 404's so this is the "catch-all" we will replace.
          o.path     = route.matched[ 0 ].path;
          o.location = route.matched[ 0 ].path;       // The matched route, which is the raw route definition, as our location
        } else {
          o.path     = route.path;                    // we hit *, which would mean a 404, so use the original url so we capture it.
          o.location = window.location.href;
        }
      } else {
        o.title    = route.name;
        o.path     = route.path;
        o.location = window.location.href;
      }
      return o;
    }
  }
})

// this data element is $root
new Vue({
          router,
          render : h => h(App),
          data   :  {
              appTitle       : "mySchool Cafe",
              appVersion     : version,
              isAuthenticated: false,
              domain         : "",
              portal         : {
                user: {
                  title: ""
                },
                aboutUrl: ""
              },
              account        : {
                tenant: {
                  tenantName: ""
                }
              },
              token          : ""
          },
          mounted() {
            console.log( "process.env" , process.env );
          },
          methods: {
            isUserAuthenticated() {
              let t = this;
              return t.isAuthenticated;
            },
            alias() {
              if(this.portal.user.title === "") {
                this.portal.user.title = "User";
              }
              return this.portal.user.title;
            },
            removePortalData() {
              let t    = this;
              axios.defaults.headers.common[ 'x-access-token' ] = null;
  
              t.portal = {
                user: {
                  title: ""
                }
              }
            },
            async confirm( title , icon = "question" ) {
              let result = await this.$swal.fire( {
                                                    icon: 'question',
                                                    title: title,
                                                    showCancelButton:true,
                                                    allowEscapeKey: true,
                                                    allowEnterKey: true
                                                  });
        
              if( result.dismiss ) {
                return false;
              }
        
              return true;
            },
            async confirmYesNo( title , icon = "question" ) {
              let result = await this.$swal.fire( {
                                                    icon: 'question',
                                                    title: title,
                                                    showCancelButton:true,
                                                    confirmButtonText: 'Yes',
                                                    cancelButtonText: 'No',
                                                    allowEscapeKey: true,
                                                    allowEnterKey: true
                                                  });
        
              if( result.dismiss ) {
                return false;
              }
        
              return true;
            },
            async alert( title , icon = 'success') {
              let result = await this.$swal.fire( {
                                                    icon: icon,
                                                    title: title,
                                                    allowEscapeKey: true
                                                  });
            },
            setToken(token) {
              this.token                                        = token;
              axios.defaults.headers.common[ 'x-access-token' ] = token;
            },
            login(userRecord) {
      
//console.log("main.js -> login() (showing userRecord)", JSON.stringify(userRecord, null, 2));
      
              this.setToken(userRecord[ "x-access-token" ]);
      
              this.account         = userRecord.account;
              this.portal          = userRecord.portal;
              //t.accessLog       = userRecord.RemoteAccessLog;
              this.isAuthenticated = true;
      
//console.log("leaving main.login() ");
      
            },
            logout() {
              let t             = this;
              t.isAuthenticated = false;
              t.account         = {};
              t.token           = "";
              
              t.removePortalData();
              
              t.$router.push({path: '/'});
            }
          }
        }).$mount("#app");

