<template>
  <aside class="dh-frame" :style="framestyle" @click="frameClicked">
    <div class="dh-titleNumber">{{titleNumber}}</div>
    <h3 :style="h3style">{{title}}</h3>
    
    <template v-if="items.length>2">
    <ul :style="ulstyle">
      <li v-for="(item,index) in items" :style="isItem(index)">{{item.title}}</li>
    </ul>
    </template>
    <template v-else>
    <div v-for="item in items" :style="ulstyle">
      <br>
      {{item.title}}<br>
      {{item.description}}
    </div>
    </template>
  </aside>
</template>
<script>
  export default {
    
    props   : {
      title         : {type: String, default: ""},
      titleNumber   : {type: [ String, Number ], default: ""},
      width         : {type: String, default: "200px"},
      height        : {type: String, default: "300px"},
      color         : {type: String, default: "#e3a03b"},
      textColor     : {type: String, default: "white"},
      fontSize      : {type: String, default: "medium"},
      textAlign     : {type: String, default: "left"},
      borderFontSize: {type: String, default: "2em"},
      items         : {type: Array, default: () => []},
      selected      : {type: Array, default: () => []}
    },
    computed: {
      framestyle() {
        let style = "width: " + this.width +
                    ";height: " + this.height +
                    ";border-bottom: 6px solid " + this.color +
                    ";border-left: 50px solid " + this.color + ";";
        return style;
      },
      h3style() {
        return "color: " + this.textColor +
               ";font-size:" + this.borderFontSize + ";"
      },
      ulstyle() {
        return "font-size:" + this.fontSize +
               ";text-align:" + this.textAlign + ";"
      }
    },
    methods: {
      frameClicked() {
        this.$emit("click", this.title);
      },
      isItem(i) {
        if(this.selected === undefined) {
          return "";
        }
        for(var j = 0; j < this.selected.length; j++){
          if(this.selected[ j ] === i) {
            console.log("setting background");
            return "background:gray;";
          }
        }
        return "background: white;";
      }
    }
    
  }
</script>
<style lang="scss">
  
  .dh-frame {
    float: left;
    margin: 1em;
    border-radius: 1em;
    position: relative;
    padding: 15px;
    display: inline-block;
  }
  
  .dh-titleNumber {
    position: absolute;
    top: -10px;
    left: -60px;
    padding: 3px;
    border-radius: 50%;
    border: 4px solid #f96332;
    width: 36px;
    height: 36px;
    font: 1.4em Arial, sans-serif;
    text-align: center;
    
    background: white;
    color: black;
    
    
  }
  
  .dh-frame h3 {
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -25px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
  }
  
  /*.dh-frame dh-text {*/
  /*  vertical-align: bottom;*/
  /*}*/
  
  .dh-frame ul {
    vertical-align: middle;
    list-style: none;
    padding-left: 0;
    
  }


</style>


