<template>
  <aside class="dh-frame" :style="frameStyle" @click="frameClicked">
    <h3 :style="h3style">{{borderTitle}}</h3>
    <h4>{{ dayNumber }}</h4>
    <!-- <div class="dh-calendar">
      <calendar-day
                :eventDate="orderDate"
                titleBorderColor="color"
                :titleColor= "color"
      ></calendar-day>
    </div> -->
    <template>
      <div class="dh-body" :style="bodyStyle">
        <div v-for="(item,index) in items" :key="index" :style="ulstyle(index)">
          <ul @click="selectItem(index)">
            <li>
              {{item.title}}
            </li>
            <li style="white-space: pre-line;">
              {{item.description}}
            </li>
            <li v-if="index===selected || selected===-1"><span v-show="menuItem.qty>1">({{menuItem.qty}})  </span> <i>{{item.price[priceLevel]}}</i></li>
          </ul>
        </div>
      </div>
    </template>
    <div class="dh-footer" :style="footerStyle" >
      <div v-show="menuItem.qty>0" @click="clearSelection()"><i class="fa fa-trash"></i></div>
      <div>{{currency(this.total)}}</div>
    </div>
  </aside>
<!-- <div v-show="menuItem.qty>0" @click="removeQty()"><i class="fa fa-minus"></i></div>
      <div v-show="menuItem.qty>0 && menuItem.qty <items[ selected ].maxChoice" @click="addQty()"><i class="fa fa-plus"></i></div> -->

</template>
<script>
  
  import { CalendarDay } from '@/components';
  import Currency        from "currency.js";
  import Moment          from "moment";
  
  export default {
    
    props     : {
      orderDate     : {type: [ Date, String, Object ], default: null},
      priceLevel    : {type: Number, default: 0},
      title         : {type: String, default: ""},
      width         : {type: String, default: "300"},
      height        : {type: String, default: "500"},
      color         : {type: String, default: "#e3a03b"},
      textColor     : {type: String, default: "white"},
      fontSize      : {type: String, default: "medium"},
      textAlign     : {type: String, default: "left"},
      borderFontSize: {type: String, default: "2em"},
      items         : {type: Array, default: () => []},
      qty           : {type: Number, default: 0},
      price         : {type: Number, default: 0}
    },
    components: {
      CalendarDay,
      Moment
    },
    computed  : {
      frameStyle() {
        let style = "width: " + this.width +
                    "px;min-width:" + this.width + 
                    "px;height: " + this.height + "px" +
                    ";background: " + this.color + 
                    ";border-bottom: 50px solid " + this.color +
                    ";border-top: 2px solid " + this.color +
                    ";border-left: 50px solid " + this.color + ";";
        return style;
      },
      h3style() {
        return "color: " + this.textColor +
               ";font-size:" + this.borderFontSize + ";"
      },
      bodyStyle() {
        let style = "width: " + (parseInt(this.width)-55).toString() +
               "px;height: " + (parseInt(this.height)-54).toString() + "px;";
        console.log( "bodyStyle()" , style );
        return style;
      },
      footerStyle() {
        return "width: " +  + (parseInt(this.width)-4).toString() + "px;";
      },
      total() {
        return this.menuItem.qty * this.menuItem.price;
      }
    },
    data() {
      return {
        monthName  : "",
        dayName    : "",
        dayNumber  : 0,
        borderTitle: this.defaultDayName(),
        menuItem   : {
          itemNumber: -1,
          priceLevel: 0,
          qty       : 0,
          price     : 0
        },
        selected: -1
      }
    },
    mounted() {
      this.menuItem.qty = this.qty;
      if( this.title !== "" ) {
        this.borderTitle = this.title;
      }
      this.makeNames();
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      defaultDayName() {
        return this.moment(this.orderDate).format('dddd');
      },
      makeNames() {
        this.dayNumber = this.moment(this.orderDate).format('D');
        this.monthName = this.moment(this.orderDate).format('MMMM');
      },
      ulstyle(itemNumber) {
        let style = "font-size:" + this.fontSize +
                    ";text-align:" + this.textAlign + ";";
        if(this.selected !== -1) {
          if(itemNumber === this.selected) {
            style += "border-radius:.5em;background:" + this.color + ";color:" + this.textColor + ";";
          } else {
            style += "color:#bbb;background:white;";
          }
        }
        return style;
      },
      frameClicked() {
        this.$emit("click", this.title);
      },
      selectItem(index) {
        if(this.selected !== index) {
          this.$emit( "clear" , this.menuItem );
          this.itemNumber          = this.items[ index ].itemNumber;
          this.selected            = index;
          this.menuItem.qty        = 1;
          this.menuItem.price      = this.items[ index ].price[ this.priceLevel ];
          this.menuItem.priceLevel = this.priceLevel;
          this.$emit("add", this.menuItem);
        }
      },
      addQty() {
        if(this.menuItem.qty < this.items[ this.selected ].maxChoice) {
          this.menuItem.qty++;
          this.$emit("add", this.menuItem);
        }
      },
      removeQty() {
        if(this.menuItem.qty > 0) {
          this.menuItem.qty--;
          if(this.menuItem.qty <= 0) {
            this.selected = -1;
          }
          this.$emit("remove", this.menuItem);
        }
      },
      clearSelection() {
        this.selected     = -1;
        this.$emit("clear", this.menuItem);   // before we reset it.
        this.menuItem.qty = 0;
      }
      
    }
    
  }
</script>
<style scoped lang="scss">
  
  .dh-frame {
    float: left;
    margin: 1em;
    border-radius: 1em;
    padding: 3px;

    background: salmon;
  }
  
  .dh-frame .dh-calendar {
    position: absolute;
    top: -80px;
    left: -75px;
  }
  
  .dh-frame h3 {
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -25px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
  }

  .dh-frame h4 {
    position: absolute;
    height: 36px;
    width: 36px;
    border: 3px solid white;
    top: -12px;
    left: -42px;
    border-radius:50%;
    background: gray;
    color:white;
    text-align: center;
    font-size:1.4em;
    font-weight:800;
  }

  
  .dh-frame ul {
    vertical-align: middle;
    list-style: none;
    padding-left: 0;
    padding-top: .7em;
    padding-bottom: .3em;
  }
  
  .dh-body li:first-child {
    font-weight: 900;
    line-height: .9em;
  }
  
  .dh-body {
    position     : absolute;
    border-radius: .75em;
    top          : 2px;
    left         : 2px;
    background: white;
    vertical-align: middle;
    display: table-cell;
    padding: 2px;
    overflow-y: auto;

    background: blue;
  }
  
  .dh-footer {
    position: absolute;
    border-radius:.75em;
    bottom: -48px;
    left: -48px;
    // margin-top: 15px;
    height: 45px;

    background: yellow;

  }
  
  .dh-footer div:first-child {
    position: absolute;
    left: 16px;
    bottom: 45px;
    font-size: 1.5em;
    color:white;
  }
  
  .dh-footer div:nth-child(2) {
    color: white;
    text-align: center;
    vertical-align: middle;
    font-size: 1.5em;
  }


  // .dh-footer div:first-child {
    /*background: black;*/
    // color: white;
    /*background: white;*/
    /*height: 36px;*/
    /*width: 36px;*/
    /*border-radius: 50%;*/
    // text-align: center;
    // vertical-align: middle;
    // float: left;
    // margin-left:.25em;
    // font-size: 1.75em;
  // }
  
  // .dh-footer div:nth-child(2) {
  //   position: absolute;
  //   bottom: 55px;
  //   left: -42px;
  //   padding: 5px;
  //   /*border-radius: 50%;*/
  //   /*border: 4px solid #f96332;*/
  //   width: 32px;
  //   height: 32px;
  //   font: 2em Arial, sans-serif;
  //   text-align: center;
  //   /*background: #ff0202;*/
  //   color: white;
  // }


</style>


