<template>
   <div class="wrapper">
      <div class="page-header page-header-mini">
         <parallax class="page-header-image"
                   style="background-image: url('/img/pexels-photo-716431.jpeg')">
         </parallax>
         <div class="content-center">
            <div class="row">
               <div class="col-md-8 ml-auto mr-auto">
                  <h1 class="title">Cafe Smile Makers</h1>
                  <h4>mySchool.cafe - Your Partner In Making Lunch-Time Smiles</h4>
               </div>
            </div>
         </div>
      </div>
      <div class="section">
         <div class="about-office">
            <div class="container">
               <div class="row text-center">
                  <div class="col-md-8 ml-auto mr-auto">
                     <h2 class="title">Our office is our second home</h2>
                     <h4 class="description">Here are some pictures from our office. We spend a lot of time here so stop
                        by and have a cup of coffee and let's chat.</h4>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-4">
                     <img class="rounded img-raised" alt="Raised Image" src="img/bg27.jpg">
                  </div>
                  <div class="col-md-4">
                     <img class="rounded img-raised" alt="Raised Image" src="img/bg24.jpg">
                  </div>
                  <div class="col-md-4">
                     <img class="rounded img-raised" alt="Raised Image" src="img/bg32.jpg">
                  </div>
                  <div class="col-md-6">
                     <img class="rounded img-raised" alt="Raised Image" src="img/project6.jpg">
                  </div>
                  <div class="col-md-6">
                     <img class="rounded img-raised" alt="Raised Image" src="img/project14.jpg">
                  </div>
               </div>
            </div>
         </div>
         <div class="about-contact">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 mr-auto ml-auto">
                     <h2 class="text-center title">Drop us a note</h2>
                     <h4 class="text-center description">
                        We are always eager to hear from you. If you have an idea to discuss, want to chat about how to
                        use mySchool.cafe in your organization, or just want to
                        kick the tires on some thoughts, we welcome the interaction.
                     </h4>
                     <div>
                       <a class="btn btn-primary btn-round btn-lg btn-block" style="font-size:2em;" href="mailto:info@newcare.io?subject=mySchool Cafe Information Request">Click here to send us an email</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import { Badge, Button, Card, Checkbox, FormGroupInput, InfoSection, SFormInput } from '@/components';
  import { Option, Select } from 'element-ui'

  export default {
    name      : 'about-us',
    bodyClass : 'about-us',
    components: {
      Card,
      InfoSection,
      Badge,
      SFormInput,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput,
      [ Select.name ]        : Select,
      [ Option.name ]        : Option,
    },
    data() {
      return {
        form: {
          firstName: '',
          email    : '',
          body     : ''
        }
      }
    },
    methods: {
      sendEmail() {

      }
    }
  }
</script>
<style>
</style>
