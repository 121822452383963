///-------------------------------------------------------------------------------------------------
// summary:	Authentication service class
///-------------------------------------------------------------------------------------------------

import Api from './Api';

export default {
  
  getFaqs() {
    const response = Api().get('/getFaqs');
    return response;
  },
  getNews() {
    const response = Api().get('/getNews');
    return response;
  },
  getBillingInformation() {
    const response = Api().get('/billingInformation');
    return response;
  },
  getPaymentList() {
    const response = Api().get('/paymentList');
    return response;
  },
  getTransactions( badgeNumber ) { /* omit the badgeNumber for all transactions for the account */
    const response = Api().get(`/transactions/${badgeNumber}`);
    return response;
  },
  saveBillingInformation(billingData) {
    const response = Api().patch('/billingInformation', billingData);
    return response;
  },
  registerCC( data ) {
    const response = Api().post( '/registerCCToken' , data );
    return response;
  },
  removeCC() {
    const response = Api().delete( '/registerCC' );
    return response;
  },
  getAccessToken() {
    const response = Api().get( "accessToken" );
    return response;
  },
  manualReload() {
    return Api().post( '/reloadAmountManual');
  },
  getBalance() {
    return Api().get( '/balance');
  },
  getOrderCalendars() {
    return Api().get( "/orderCalendars" );
  },
  getOrderCalendar( id ) {
    return Api().get( `/orderCalendar/${id}` );
  },
  getCategories( ) {
    return Api().get( `/itemCategories`);
  },
  getMenuItems( category ) {
    return Api().get( `/menuItems/${category}`);
  },
  getWeeklyOrders( orderDate, studentId ) {
    return Api().get( `/weeklyOrders/${orderDate}/${studentId}`);
  },
  getOrders( calendarNumber, studentId ) {
    return Api().get( `/orders/${calendarNumber}/${studentId}`);
  },
  postOrder( order ) {
    return Api().post( "/order" , order );
  },
  updateOrder( order ) {
    return Api().patch( "/order" , order );
  },
  deleteOrder( id ) {
    return Api().delete( `/order/${id}` );
  },
  getStudents() {
    return Api().get( "/students");
  },
  linkStudent( regInfo ) {
    return Api().post( "/linkStudent" , regInfo );
  },
  unlinkStudent( student ) {
    return Api().post( "/unlinkStudent" , student );
  }
  
  
}
