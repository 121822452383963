<template>
  <div class="faq-wrapper p-sm-3 p-2 mb-3" v-b-toggle="`accordion${index}`">
    <div class="question d-flex pl-3" variant="info">
      <div class="label align-self-start">Q<span class="dot"></span></div>
      <div class="question-title ml-3">{{faq.title}}</div>
    </div>
    <b-collapse :id="`accordion${index}`" accordion="my-accordion" role="tabpanel">
      <div class="answer d-flex pl-3">
        <div class="label align-self-start">A<span class="dot"></span></div>
        <div class="answer-description ml-3">
          {{faq.description}}
          <span v-if="faq.moreInformation">
            <a :href="faq.moreInformation" target="_blank"><span class="info-icon" v-b-popover.hover.top="faq.tip || 'More Information'"></span></a>
          </span>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    name: 'FaqCard',
    props: {
      faq: { type: Object, default: () => { } },
      index: { type: Number }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .faq-wrapper {
    border: 1px solid #eee;
    border-radius: .5em;
    box-shadow: 0 2px 3px 0px #eee;
    text-align: left;

    .question {
      border-left: 5px solid #FF0202;
      align-items: center;
      cursor: pointer;

      .label {
        font-size: 45px;
        color: #FF0202;
        position: relative;

        @media screen and (max-width: 576px) {
          font-size: 30px;
        }

        .dot {
          height: 6px;
          width: 6px;
          background-color: #FF0202;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          bottom: 20px;

          @media screen and (max-width: 576px) {
            bottom: 13px;
          }
        }
      }

      .question-title {
        font-size: 18px;
        font-weight: 600;
        color: rgba(35, 35, 35, 0.7);
      }
    }

    .answer {
      /* fixme: when a multi-line answer, the answer does not have enough padding at the top -- not sure how to fix that... */
      position: relative;
      border-left: 5px solid green;
      align-items: center;
      padding-left: 15px;
      white-space: pre-line;
      .label {
        font-size: 45px;
        color:green;
        position: relative;

        @media screen and (max-width: 576px) {
          font-size: 30px;
        }

        .dot {
          height: 6px;
          width: 6px;
          background-color: green;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          right:-9px;
          bottom: 20px;

          @media screen and (max-width: 576px) {
            bottom: 13px;
          }
        }
      }

  .answer-description {
    font-size: 18px;
    color: #aaa;
    display: flex;
    .info-icon {
    background-image: url('../../assets/img/info-icon.png');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    float: left;
    display: inline-block;
    /*position: absolute;*/
    margin-left: 10px;
  }

  }
  }
  }
</style>
