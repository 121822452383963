<template>
  <div id="addStudents" class="wrapper">
    <div class="page-header page-header-smalltop">
      <parallax class="page-header-image" style="background-image: url('/img/back-to-school-start-MACVKugZ8WQ.jpg')">
      </parallax>
    </div>

    <div class="section">
      <div class="row">
        <div  class="col-md-5 card ml-auto mr-auto" style="border-radius:1.5em;background-color: darkslategray">
            <div class="mt-3 mr-0 mb-0 float-right" >
              <a href="\video\LinkingStudent-Lindy.mp4" target="_blank"><i style="font-size:1.5em;color:red;margin-right:8px;vertical-align:middle;" v-b-popover.hover.top="'Video: How to Add/Link a student'" class="fa fa-film"></i></a>
              <button class="btn btn-outline-warning btn-round btn-sm" @click="isAddingStudent=true; newStudent.id=''">
                Add Student
              </button>
            </div>
          <h2 class="title ml-3" style="color:white;">{{accountsTitle}}</h2>

          <b-tabs card class="mr-3 ml-3 pl-3" content-class="pr-3 mt-1 " style="background-color:white;border-radius:.8em;">
            <h3 class="card-title text-center" v-if="students.length===0">Please Add Your Student</h3>
            <template v-for="(student,index) in students">
              <b-tab :title="student.firstName" :key="index" style="width:100%;">
                <i class="fa fa-trash show-hand" style="font-size:1.5em;position:absolute;right:50px;top:120px;" @click="removeStudent( student )" v-b-popover.hover="'This action unlinks the selected student from your account and returns them to the available-registrations list.'"></i>
                <template>
                  <s-form-input label="Student ID" disabled type="text" v-model="student.badgeNumber"></s-form-input>
                  <s-form-input label="Grade" disabled type="text" v-model="student.grade"></s-form-input>
                  <s-form-input label="Home Room" disabled type="text" v-model="student.homeRoom"></s-form-input>
                  <s-form-input label="Teacher" disabled type="text" v-model="student.teacher"></s-form-input>
                </template>
              </b-tab>
            </template>
          </b-tabs>
          <!-- <h3 class="card-title float-right mr-3" style="color:lightgreen;">Balance: ${{currency(accountBalance)}}</h3> -->
        </div>
        <div class="col-md-6 card ml-auto mr-auto" style="border-radius:1.5em;background-color: darkslategray">
          <button v-if="(!billingInformation.ccInfo.isAuthorized) && (billingInformation.ccInfo.ownerEmail>'')" class="btn btn-outline-warning btn-round mt-3 mr-0 mb-0 btn-sm float-right" @click="registerCreditCard()">
            Add Credit Card
          </button>
          <div style="display:inline-block;margin-left:10px;">
            <h2 class="title" style="display:inline-block;color:white;vertical-align:middle;">{{paymentTitle}}</h2>
            <a href="\video\paymentDetails.mp4" target="_blank"><i style="vertical-align:middle;margin-left:10px;font-size:1.5em;color:red;" v-b-popover.hover.top="'Video: Payment Details Overview'" class="fa fa-film"></i></a>
          </div>
          <div class="mr-3 ml-3 mb-2" style="background-color:white;border-radius:.8em;">

            <h3 class="ml-3 pt-2">Your Billing Information
              <small v-if="!isCCAuthorized()" style="margin-left:2em;color:#ff0202;">(All Fields Required)</small>
            </h3>
            <p v-if="billingInformation.ccInfo.isAuthorized" style="padding-left:2em;font-size:.8em;color:red;font-weight:800;">NOTE: For security, once your credit card has been activated on your account, you cannot edit the billing address.  If you wish to edit the billing information, first remove your credit card, change the billing details, and then re-add the credit card.</p>
            <div class="mr-5" @input="isBillingChanged=true;">
              <s-form-input label="First Name" :readonly="isCCAuthorized()" type="text" v-model.trim="billingInformation.ccInfo.firstName" placeholder="Match Your Billing Information..."></s-form-input>
              <s-form-input label="Last Name" :readonly="isCCAuthorized()" type="text" v-model.trim="billingInformation.ccInfo.lastName"></s-form-input>
              <s-form-input label="Address" :readonly="isCCAuthorized()" type="text" v-model.trim="billingInformation.ccInfo.address1"></s-form-input>
              <s-form-input label="City" :readonly="isCCAuthorized()" type="text" v-model.trim="billingInformation.ccInfo.city"></s-form-input>
              <s-form-input label="State" :readonly="isCCAuthorized()" cstyle="width:6em;" type="text" v-model.trim="billingInformation.ccInfo.state"></s-form-input>
              <s-form-input label="Zip" :readonly="isCCAuthorized()" cstyle="width:10em;" type="text" v-model.trim="billingInformation.ccInfo.zipCode"></s-form-input>
              <s-form-input label="Phone" :readonly="isCCAuthorized()" cstyle="width:10em;" type="text" v-model.trim="billingInformation.ccInfo.phone"></s-form-input>
              <s-form-input label="Email" type="email" v-model.trim="billingInformation.ccInfo.ownerEmail" placeholder="For sending receipts..."></s-form-input>
              <i class="fa fa-question float-right ml-2 mt-2 show-hand" v-b-popover.hover="billingRefHelpText" @click="showHelp(billingRefHelpText)"></i>
              <s-form-input label="Billing Reference" type="text" v-model.trim="billingInformation.reference" placeholder="Your Memo, NOT CC#; e.g., School Lunch"></s-form-input>

              <i class="fa fa-question float-right ml-2 mt-2 show-hand" v-b-popover.hover="eoyFunds" ></i>
              <b-form-group  v-if="portal" horizontal label-cols-lg="3" label-class="text-sm-right" label="End Of Year Balance">
                <b-form-select class="form-control"  v-model="portal.endOfYear.remainingBalanceAction" :value="portal.endOfYear.remainingBalanceAction" :options="eoyBalanceOptions">
                </b-form-select>
              </b-form-group>

              <!-- <div v-if="portal" style="width:50%;padding:.5em;border-radius:.5em;margin-left:auto;margin-right:auto;background:#f7f7f7;">
                <p style="margin-left:3em;text-align:left;font-weight:500;font-size:.95em;">How would you like any remaining funds managed at the end of the school year?</p>
                <b-form-radio-group id="radio1" style="margin-left:5em;" stacked v-model="portal.endOfYear.remainingBalanceAction" :options="eoyBalanceOptions" >
                </b-form-radio-group>
              </div> -->

              <notifications></notifications>
              <b-button class="btn btn-warning btn-round  btn-sm float-right mt-0"  v-show="isBillingChanged" @click="saveBillingInformation()">
                Save Changes
              </b-button>

              <br>
              <hr>
              <template v-if="billingInformation.ccInfo.isAuthorized">
                <div>
                  <loading :active.sync="isShowLoading" :is-full-page="true" :height="200" :width="200" color="coral"></loading>
                  <div class="col-md-12 ">
                    <i class="fa fa-trash show-hand" style="position:absolute;top:4px;right:0;float:right;font-size:1.5em;" @click="removeCreditCard()" v-b-popover.hover="'Remove this credit card from your account.  Once removed, you may add another (or the same if only the billing information changed)'"></i>
                    <b-form>
                      <s-form-input label="Card Type" cstyle="width:5em;" readonly type="text" v-model="billingInformation.ccInfo.ccType"></s-form-input>
                      <s-form-input label="Card Number" cstyle="width:20em;" readonly type="text" v-model="billingInformation.ccInfo.ccNumber"></s-form-input>
                      <s-form-input label="Expiration Date" cstyle="width:10em;" readonly type="text" v-model="billingInformation.ccInfo.ccExpDate"></s-form-input>
                    </b-form>
                  </div>
                </div>
              </template>
              <template v-if="!billingInformation.ccInfo.isAuthorized && ! isAddingCreditCard && !isBillingChanged && billingInformation.ccInfo.ownerEmail.indexOf('@')>0">
                <div>
                  <button class="ml-5 btn btn-outline-warning btn-round btn-sm " @click="registerCreditCard()">
                    Add Credit Card
                  </button>
                </div>
              </template>
              <b-form-group v-if="billingInformation.ccInfo.isAuthorized" horizontal label-cols-lg="3" label-class="text-sm-right" label="Reload Value">
                <b-form-select class="form-control" style="width:10em;" v-model="billingInformation.autoReloadTotal" :value="billingInformation.autoReloadTotal" :options="reloadValues">
                </b-form-select>
                <!--                <b-checkbox v-model="billingInformation.options.isAutoReload"> Automatically Reload</b-checkbox>-->
                <button v-if="!isAddingCreditCard && !isBillingChanged" class="ml-5 btn btn-success btn-round btn-sm " @click="reloadStudentBalance()">
                  Load This Amount Now
                </button>
                 <b-button class="btn btn-warning btn-round  btn-sm float-right mt-0"  v-show="isBillingChanged" @click="saveBillingInformation()">
                Save Changes
                </b-button>
                <div v-if="reloadAccountError.length>0" @click="reloadAccountError=''" class="">
                  <b-alert variant="danger" show>{{ reloadAccountError }}</b-alert>
                </div>
              </b-form-group>

              <br>
            </div>
            <template v-if="isAddingCreditCard">
              <loading :active.sync="isShowLoading" :is-full-page="true" :height="200" :width="200" color="coral"></loading>
              <img src="/img/creditcard-logos.png" />
              <h3 class="card-title ml-2">Register Your Credit Card</h3>
              <div v-show="addCreditCardError.length>0" class="">
                <b-alert dismissible variant="danger" show>{{ addCreditCardError }}</b-alert>
              </div>
              <s-form-input iRef="ccnumber"  id="cc-number" label="Card Number" cstyle="width:20em;" type="text" v-model="newCC.card" placeholder="Your Credit Card"></s-form-input>
              <s-form-input id="cc-expire" label="Expiration Date" cstyle="width:10em;" type="text" v-model="newCC.expDate" placeholder="MMYY"></s-form-input>
              <s-form-input id="cc-cvv" label="CVV" cstyle="width:10em;" type="text" v-model="newCC.cvv"></s-form-input>
              <button v-show="!isShowLoading" class="ml-5 btn btn-success btn-round btn-sm " @click="registerCreditCard()">
                I'm Ready, Let's Authorize This Card
              </button>
              <button v-show="!isShowLoading" class="ml-5 btn btn-danger btn-round btn-sm " @click="isAddingCreditCard = false;">
                Nope, Not Right Now
              </button>
            </template>
          </div>
          <button class="btn btn-secondary btn-round btn-sm float-right mt-2 mr-3 " @click="transactionSummary()">
            Payments Summary
          </button>
          <h3 class="card-title ml-3 mt-3 mr-3" style="color:lightgreen;">Balance: ${{currency(accountBalance)}}</h3>
        </div>
      </div>

      <div class="row" id="ccSummary">
        <div v-if="showCCSummary" class="col-md-6 ml-auto mr-auto text-center">
          <h2 class="title">Payment Listing </h2>
          <el-table :default-sort="{ prop: 'transactionDate', order: 'descending'}" :data="paymentList">
            <el-table-column width="60" min-width="60" type="index" sortable></el-table-column>
            <el-table-column min-width="150" prop="transactionDate" label="Date" sortable>
              <template slot-scope="scope">
                {{moment( scope.row.transactionDate ).format( "DD-MMM-YY hh:mma")}}
              </template>
            </el-table-column>
            <el-table-column min-width="150" prop="title" label="Action" sortable></el-table-column>
            <el-table-column min-width="100" align="right" prop="tenderAmount" label="Total" sortable >
              <template slot-scope="scope">
                {{currency( scope.row.tenderAmount )}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <modal :show.sync="isAddingStudent" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">Link Your Student</h4>
      <p style="font-weight:500;">Linking a student connects a pre-registered student to your account. Refer to your registration sheet for these details.
      </p>
      <s-form-input label="Student ID" cstyle="width:14em;" type="text" v-model="newStudent.id"></s-form-input>
      <s-form-input label="Student DOB" cstyle="width:14em;" type="text" placeholder="mm/dd/yyyy" v-model="newStudent.dob"></s-form-input>
      <h4 class="text-center" style="color:orangered">{{addStudentError}}</h4>
      <p v-if="isAddingMessage">
        {{ isAddingMessage }}
      </p>
      <template slot="footer">
        <n-button type="danger" @click.native="isAddingStudent = false">Cancel</n-button>
        <n-button type="success" @click.native="addStudent()">Add This Student</n-button>
      </template>
    </modal>

  </div>
</template>
<script>

  import { Badge, Button, Card, Checkbox, FormGroupInput, InfoSection, Modal, SFormInput, TabPane, Tabs } from '@/components';
  import GeneralService                                                                                   from "@/services/GeneralService";
  import Shift4                                                                                           from "@/services/Shift4";
  import Currency                                                                                         from "currency.js";
  import { Table, TableColumn }                                                                           from 'element-ui';
  import Moment                                                                                           from "moment";
  import Loading                                                                                          from "vue-loading-overlay";
  import Datepicker from 'vuejs-datepicker';
  import "vue-loading-overlay/dist/vue-loading.css";

  export default {
    name      : 'profile-page',
    bodyClass : 'landing-page',
    components: {
      SFormInput,
      InfoSection,
      Card,
      Tabs,
      TabPane,
      Modal,
      Badge,
      Datepicker,
      Loading,
      [ Button.name ]        : Button,
      [ Checkbox.name ]      : Checkbox,
      [ FormGroupInput.name ]: FormGroupInput,
      [ Table.name ]         : Table,
      [ TableColumn.name ]   : TableColumn
    },
    data() {
      return {
        isShowLoading   : false,
        isBillingChanged: false,
        isStudentChanged: false,
        isAddingMessage : "",
        portal          : null,
        accountsTitle   : "Students",
        paymentTitle    : "Payment Details",
        accountBalance  : 0.00,
        availableSpend  : 0.00,
        reloadValues    : [
          {value: 0, text: "Please Select"},
          {value: 25, text: "$25.00"},
          {value: 50, text: "$50.00"},
          {value: 75, text: "$75.00"},
          {value: 100, text: "$100.00"},
          {value: 125, text: "$125.00"},
          {value: 150, text: "$150.00"},
          {value: 170, text: "$175.00"},
          {value: 200, text: "$200.00"}
        ],
        billingRefHelpText: "This is your REFERENCE for tracking on your statement and email notifications.  e.g., Suzie Lunch or School Lunch.  Please do NOT enter your credit card number here.",
        eoyFunds: "This determines your preference for how the remaining balance in your account is managed at the end of the school year.",
        showCCSummary     : false,
        isAddingCreditCard: false,
        addCreditCardError: "",
        isAddingStudent   : false,
        addStudentError   : "",
        reloadAccountError: "",
        paymentList       : [],
        newCC             : {
          card   : "",
          expDate: "",
          cvv    : ""
        },
        balance           : {
          "accountNumber": "",
          "totalClassId" : 0,
          "totalType"    : "debit",
          "balance"      : 0,
          "total"        : {
            "chargeTotal" : 0,
            "chargeQty"   : 0,
            "paymentTotal": 0,
            "paymentQty"  : 0
          }
        },
        billingInformation: {
          "options"      : {
            "isAutoReload"  : false
          },
          "ccInfo"       : {
            "ccNumber"    : "",
            "ccType"      : "",
            "ccExpDate"   : "",
            "isAuthorized": false,
            "isDebit"     : false,
            "firstName"   : "",
            "lastName"    : "",
            "address1"    : "",
            "city"        : "",
            "state"       : "",
            "zipCode"     : "",
            "phone"       : "",
            "ownerEmail"  : ""
          },
          title          : "",
          reference      : "",
          autoReloadTotal: 0,
          transactionList: []
        },
        eoyBalanceOptions: [
          { text: 'Donate Unused to School', value: 'donate' },
          { text: 'Rollover Unused Until Next Year', value: 'rollover' },
          { text: 'Refund Unused to My Card', value: 'refund' }
        ],
        newStudent    : {
          studentId: "",
          dob      : ""
        },
        students      : [],

        form          : {
          firstName : '',
          email     : '',
          speciality: ''
        },
        studentRecords: [ 'one' ]
      }
    },
    computed  : {
      summaryTotal: function() {
        let total = 0;
        for(let i = 0; i < this.paymentList.length; i++){
          total += parseFloat(this.paymentList[ i ].tenderAmount);
        }
        return total;
      }
    },
    async mounted() {
      await Promise.all([
                          this.getBillingInformation(),
                          this.getStudents(),
                          this.getBalance()
                        ])

    },
    async beforeRouteLeave( to , from, next ) {
      if( !this.isBillingChanged && ! this.isStudentChanged && !this.isAddingCreditCard) {
        return next();
      }
      if( await this.$root.confirmYesNo('You have unsaved changes, continue leaving without saving?', "warning")) {
        return next();
      }
      next(false)
    },
    methods   : {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      async showHelp(helpText) {
        await this.$root.alert(helpText);
      },
      isCCAuthorized() {
        return this.billingInformation.ccInfo.isAuthorized
      },
      makeReloadValueSelectionList() {
        let t          = this;
        let minValue   = Math.min((25 * (t.students.length - 1)) + 25, 75);
        t.reloadValues = [];

        if(t.billingInformation.autoReloadTotal < minValue) {
          t.isBillingChanged = true;
          t.billingInformation.autoReloadTotal = minValue;
        }

        for(var i = minValue; i <= 200; i += 25){
          t.reloadValues.push({value: i, text: "$" + t.currency(i).toString()});
        }
      },
      async getStudents() {

        let t = this;

        try {
          let response  = await GeneralService.getStudents();
          this.students = response.data.students;

        } catch(error) {
          // todo: tell the user about this issue...
          console.error(error);
        }
        this.makeReloadValueSelectionList();
      },
      async addStudent() {
        let t = this;

        let regInfo = {
          table   : "student",
          idNumber: t.newStudent.id,
          dob     : t.newStudent.dob
        }

        if(regInfo.idNumber === "") {
          t.addStudentError = "Please Enter a Student Id";
          return;
        }

        if(regInfo.dob === "") {
          t.addStudentError = "Please Enter a DOB";
          return;
        }

        if( parseInt(regInfo.dob.substring( 0 ,2 )) > 12 ) {
          t.addStudentError= "The Date Format is MM-DD-YYYY";
          return;
        }

        if( regInfo.dob.indexOf( "/") < 0 && regInfo.dob.indexOf( "-") <0 ) {
          if( regInfo.dob.length === 8 ) {
            let dob = regInfo.dob.replace(/(\d{2})(\d{2})(\d{4})/, "$1-$2-$3");
            regInfo.dob = dob;
            t.newStudent.dob = dob;
          } else {
            t.addStudentError =  "Invalid Date Format - Please Add Slashes"
            return;
          }
        }

        if( regInfo.dob.length !== 10 ) {
          t.addStudentError = "The Date Format is MM-DD-YYYY (leading zeros also please)";
          return;
        }

        t.addStudentError = "";
        t.reloadAccountError = "";

        try {
          let result = await GeneralService.linkStudent(regInfo);
          this.getStudents();
          this.makeReloadValueSelectionList();
          this.isAddingStudent = false;
        } catch(error) {
          console.error(error);

          if(error.response) {
            if(error.response.data.message) {
              let message = error.response.data.message;
              if(error.response.data.reason) {
                message += "  " + error.response.data.reason
              }
              t.addStudentError = message;
            }
          } else {
            t.addStudentError = "OOPS - Had an issue adding this student";
          }

          setTimeout(function() {         // todo: make this a more generic routine
            t.addStudentError = "";
          }, 5000);
        }
      },
      async removeStudent(student) {
        if(student.studentId > "") {
          if(!await this.$root.confirmYesNo("Un-select this student from your account: " + student.firstNname + "?")) {
            return;
          }

          student.table = "student";    // gotta have this.
          try {
            let result = await GeneralService.unlinkStudent( student );

            this.getStudents();
            this.makeReloadValueSelectionList();

            this.notify("success", "Student record has been unassigned from your account" );

          } catch( error ) {
            // todo: tell the user about this
            this.notify("error", error.message );
            console.error( error );
          }

        }
      },
      async saveStudents() {
        await this.$root.alert("Save Students - Not Implemented Yet");
      },
      async reloadStudentBalance() {
        let reloadTotal = parseFloat(this.billingInformation.autoReloadTotal);

        if(!await this.$root.confirmYesNo("Add $" + this.currency(reloadTotal).toString() + " To Your Account?")) {
          return;
        }

        if(!await this.$root.confirmYesNo("This action will cause your credit card to be charged $" + this.currency(reloadTotal).toString() + "   Continue?")) {
          return;
        }

        let response;
        this.reloadAccountError = "";
        this.addCreditCardError = "";

        try {
          this.isShowLoading = true;
          response           = await GeneralService.manualReload();

          this.accountBalance = response.data.total.balance;

          this.showCCSummary = false;   // turn this off cuz the call turns it on.
          this.transactionSummary();

          this.reloadAccountError = "";
          this.notify("info", this.currency(reloadTotal).toString() + " Has Been Added");

        } catch(error) {

          if(error.response) {
            if(error.response.data.message) {
              let message = error.response.data.message;
              if(error.response.data.reason) {
                message += "  " + error.response.data.reason
              }
              this.setReloadAccountError(message)
            }
          } else {
            this.setReloadAccountError("oops, had an issue");    // fixme: this handles the notify and text and sets timeout
          }

        }

        this.isShowLoading = false;
      },
      async setReloadAccountError(message) {
        let t = this;
        t.notify("danger", message);
        t.reloadAccountError = message;
      },
      setAddCCError(message) {
        let t = this;
        t.notify("danger", message);
        t.addCreditCardError = "[x]   " + message;
        setTimeout(function() {
          t.addCreditCardError = "";
        }, 5000)
      },
      async saveBillingInformation() {
        let t                = this;
        try {
          let response         = await GeneralService.saveBillingInformation({billing: t.billingInformation, portal: t.portal });
          t.billingInformation = response.data.billing;
          t.isBillingChanged = false;
          t.notify("success", "Your information has been saved.");
        } catch( error ) {
          console.log( error );
          if(error.response) {
            if(error.response.data.message) {
              this.setAddCCError(error.response.data.message)
            }
          } else {
            this.setAddCCError(error.message);
          }
        }
        t.makeReloadValueSelectionList();
      },
      async saveOptions() {
        try {
            await GeneralService.saveOptions( portal.endOfYear );
        } catch( error ) {
          console.log( error );
        }
      },
      async getBillingInformation() {
        let t                = this;
        try {
          let response             = await GeneralService.getBillingInformation();
              t.billingInformation = response.data.billing;
              t.portal             = response.data.portal;
              t.accountNumnber     = response.data.accountNumber;
              t.accountTitle       = response.data.accountTitle;
              t.isBillingChanged   = false;
        } catch( error ) {
          console.log( error );
        }
        t.makeReloadValueSelectionList();
      },
      async getPaymentList() {
        let t         = this;
        try {
          let response  = await GeneralService.getPaymentList();
          t.paymentList = response.data.transactions;
        } catch (error ) {
          console.log( error );
          if(error.response) {
            if(error.response.data.message) {
              this.setAddCCError(error.response.data.message)
            }
          } else {
            this.setAddCCError(error.message);
          }
        }
      },
      async registerCreditCard() {
        if(this.billingInformation.ccInfo.isAuthorized && !this.isAddingCreditCard) {
          await this.$root.alert("Only One Credit Card Allowed");
          return;
        }

        this.reloadAccountError = "";
        this.addCreditCardError = "";

        if(this.isAddingCreditCard) {

          this.newCC.expDate = this.newCC.expDate.replace("-","").replace("/","");

          if(this.newCC.card.length < 15 || this.newCC.card.length > 16) { return this.setAddCCError("Invalid Card Number"); }
          if(this.newCC.cvv.length < 3 || this.newCC.cvv.length > 5) { return this.setAddCCError("Incorrect C V V length (see back of card)"); }
          if(this.newCC.expDate.length !== 4) { return this.setAddCCError("Check Expiration Date - Should Be 4 Characters  'MMYY'"); }

          if(this.billingInformation.ccInfo.firstName === 0) { return this.setAddCCError("First Name Required"); }
          if(this.billingInformation.ccInfo.lastName === 0) { return this.setAddCCError("Last Name Required"); }
          if(this.billingInformation.ccInfo.address1 === 0) { return this.setAddCCError("Address Required"); }
          if(this.billingInformation.ccInfo.city === 0) { return this.setAddCCError("City Required"); }
          if(this.billingInformation.ccInfo.state === 0) { return this.setAddCCError("State Required"); }
          if(this.billingInformation.ccInfo.zipCode === 0) { return this.setAddCCError("Zipcode Required"); }
          if(this.billingInformation.ccInfo.phone === 0) { return this.setAddCCError("Phone Number Required"); }
          if(this.billingInformation.ccInfo.ownerEmail === 0) { return this.setAddCCError("Billing Email Address Required"); }

          let response;

          try {
            this.isShowLoading = true;

            response  = await GeneralService.getAccessToken();
            let token = response.data;

            if(token.i4go_response !== "SUCCESS") {
              this.setAddCCError("Oops, Unable to Retrieve AccessToken");
              this.isShowLoading = false;
              return;
            }

            token.ccInfo = this.billingInformation.ccInfo;
            token.newCC  = this.newCC;
            response = await Shift4.tokenizeCard(token);
            response = await GeneralService.registerCC(response.data);

            this.billingInformation = response.data.billing;
            this.accountNumnber     = response.data.accountNumber;
            this.accountTitle       = response.data.accountTitle;
            this.isShowLoading      = false;
            this.isBillingChanged   = false;
            this.isAddingCreditCard = false;
            this.newCC.card         = "";
            this.notify("success", "Card Registered Successfully");

            return;
          } catch(error) {
            this.isShowLoading = false;
            console.log( "register CC Error: ", error);
            if(error.response) {
              if(error.response.data.message) {
                this.setAddCCError(error.response.data.message)
                await this.$root.confirmYesNo( error.response.data.message , 'warning');
              }
            } else {
              this.setAddCCError(error.message);
              await this.$root.confirmYesNo( error.message , 'warning');
            }
          }
          this.isShowLoading = false;
        } else {
          let t=this;
          t.isAddingCreditCard = true;
          t.newCC.card         = "";
          t.newCC.cvv          = "";
          t.newCC.expDate      = "";
          this.$nextTick(() => {
            let e = document.getElementById( "cc-number" );
            if( e ) {
              e.focus();
            }
          });
        }
      },
      async removeCreditCard() {
        if(this.billingInformation.ccInfo.ccNumber === "") {
          return;
        }

        if(!await this.$root.confirmYesNo("Remove/Unregister This Credit Card? " + this.billingInformation.ccInfo.ccNumber)) {
          return;
        }

        let response;
        try {
          this.isShowLoading      = true;
          response                = await GeneralService.removeCC();
          this.billingInformation = response.data.billing;
          this.isBillingChanged   = false;
          this.isAddingCreditCard = false;
          this.notify("success", "Card Removed Successfully");
        } catch(error) {
          console.log(error);
          if(error.response) {
            if(error.response.data.message) {
              this.setAddCCError(error.response.data.message)
            }
          } else {
            this.setAddCCError("oops, had an issue removing card");
          }
        }
        this.isShowLoading = false;
      },
      async transactionSummary() {
        this.showCCSummary = !this.showCCSummary;

        if(this.showCCSummary) {
          await this.getPaymentList();
          this.$router.push( "#ccSummary" );
        }
      },
      async getBalance() {
        let response;

        try {
          response            = await GeneralService.getBalance();
          this.accountBalance = response.data.balance;
          this.availableSpend = response.data.availableSpend;
        } catch(error) {
          console.error(error);
          this.notify("danger", error.message);
        }
      },
      notify(type, message) {
        let t = this;
        let icon;

        if(type === "danger") {
          icon = "fa fa-error";
        } else if(type === "success") {
          icon = "fa fa-ok";
        } else if(type === "error") {
          type = "danger";
          icon = "fa fa-danger";
        } else {
          icon = "fa fa-warning";
        }

        t.$notify({
                    message        : message,
                    icon           : icon,
                    horizontalAlign: 'center',  // right | center | left
                    verticalAlign  : 'bottom',  // top | bottom
                    type           : type       // info | warning | danger | success | primary
                  })
      }

    }
  }
</script>
<style scoped lang="scss">

</style>

