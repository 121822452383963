<template>
  <div id="app">
    <router-view name="header" />
    <div>
      <b-button v-if="updateExists" style="position:absolute;top:0;left:0;z-index:9999;font-weight:700;background:red;color:white;" @click="refreshApp" >
        A new app version is available! Click here to update.
      </b-button>
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: "app",
  bodyClass: "app",
  components: {},
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      title: "Main App",
    };
  },
  mounted() {},
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    updateAvailable(message) {
      alert(message);
    },
    refreshApp() {
      console.log("clicked refreshApp()");
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showRefreshUI(e) {
      // this fires but doesn't show.
      console.log("app.vue->showRefreshUI()");
      this.registration = e.detail;
      this.updateExists = true;
    },
  },
};
</script>
