<template>

  <div class="calendar-window">

    <b-row class="non-printable">
      <b-col class="text-center" style="">
        <button class="btn btn-outline-primary " @click="done()">BACK</button>
        <button class="btn btn-outline-primary" @click="print('printable')">PRINT</button>
        <b-checkbox v-model="isShowDescriptions">Show Descriptions</b-checkbox>
      </b-col>
    </b-row>

    <b-row style="">
      <b-col  class="student-info" style="">Just For: <span style="font-weight:700;">{{ student.firstName }}</span><br />Teacher: {{ student.teacher }}, Grade {{ student.grade }} </b-col>
      <b-col  class="calendar-title text-center" style="vertical-align:middle;">{{ moment(orders[0].orderDate ).format( "MMMM, YYYY" )}}</b-col>
      <b-col  style=""><img alt="mySchool" src="/img/myschoolCafe3.png" class="logo"></b-col>
    </b-row>


    <b-row class="">

      <template v-for="(weekday,wd) in days">
        <b-col sm="12" class="col-bg width-20 week-title no-order" :class="weekday.toLowerCase()" style="border:1px black solid;padding:10px;font-size:16px;" :key="wd" >
          {{weekday}}
        </b-col>
      </template>

      <b-col sm="12" class="calendar-before col-bg width-20" v-for="(before,aIndex) in generateBefore()" :key="aIndex" >
<!--        <b-col class="week-content"></b-col>-->
      </b-col>

      <template v-for="(order,tIndex) in myOrderList" >
        <b-col sm="12" class="col-bg width-20" :key="'t'+tIndex.toString()"  :class="orderKey(order)" style="" @click="selectDay(moment(dayOne).add(tIndex,'day'))">
          <b-row v-if="order" >
            <b-col class="show-hand"  style="padding:0;">
              <div class="week-day-title" :class="moment(dayOne).add(tIndex,'day').format('dddd').toLowerCase()" style="">{{1+tIndex}}</div> 
              <div class="week-content pt-1 pb-2" >
                <div v-for="(item,oIndex) in order.items" :key="'o'+oIndex">
                  <div class="item-detail">
                    <div class="item-content">
                      <div class="item-title" >
                        {{ item.title }} <span v-if="item.qty!=1">({{item.qty}})</span>
                      </div>
                    </div>
                    <div v-if="isShowDescriptions" class="item-description">{{item.description}}</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="no-order" style="padding:0;" :class="orderKey(order)" >
              <div class="week-day-title" :class="moment(dayOne).add(tIndex,'day').format('dddd').toLowerCase()">{{1+tIndex}}</div>
              <div class="week-content pt-1 pb-2" style="">
                <span></span>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </template>

    </b-row>
  </div>

</template>
<script>
  import { Button, CalendarDay, Card, } from '@/components';
  import Moment                         from "moment";

  export default {
    name: "OrderCalendarCard",
    components: {
      Button,
      CalendarDay,
      Card
    },
    data() {
      return {
        isShowDescriptions: true,
        dayOne: Moment(),
        days: {
          'Sun': 'Sunday',
          'Mon': 'Monday',
          'Tue': 'Tuesday',
          'Wed': 'Wednesday',
          'Thu': 'Thursday',
          'Fri': 'Friday',
          'Sat': 'Saturday'
        },
        selectedDate: {},
        showPrice: false,
        myOrderList: []

      };
    },
    props: {
      orders: { type: Array, default: [] },
      student: { type: Object, default: () => { } },
    },
    computed: {
      numItems() {
        // NOTE: the # of items on an order is the sum of the qty field
        return (this.order) ? this.order.items.reduce((qty, item) => qty + item.qty, 0) : 0;
      }
    },
    created() {
      this.myOrderList= new Array(this.moment(this.orders[0].orderDate ).daysInMonth());
      this.dayOne = this.moment( this.orders[0].orderDate).startOf('month');

      // console.log( "myOrderList Size" , this.myOrderList.length );

      this.orders.forEach( o => {
        this.myOrderList[ this.moment( o.orderDate ).format("D")-1] = o;
        // console.log( this.moment( o.orderDate ).format("D") );
      });
      // console.log( "orderList" , this.myOrderList );
    },
    methods: {
      moment(d) {
        return Moment(d);
      },
      setDate(date) {
        this.selectedDate = date
      },
      myFilter( o ) {
        if( o ) {
          return true;
        } else {
          return false;
        }
      },
      selectDay( day ) {
        this.$emit( "click" , day);
      },
      orderKey(o) {
        if( o ) {
          return this.moment(o.orderDate).format('ddd');
        } else {
          return "none";
        }
      },
      done() {
        this.$emit("done");
      },
      print() {
        window.print();
      },
      generateBefore() {
        let num = this.moment( this.orders[0].orderDate ).startOf("month").day();
        // console.log( "First Day" , num );
        let a = new Array(num).fill(1);
        // console.log( "Array prefill" , a );
        return a;
      },
      dailyOrder( d ) {

        return order[d];
      }


    }
  };
</script>
<style lang="scss" >


  @media print {

    // @page {
    //   size: A4 portrait;
    //   max-height:100%;
    //   max-width:100%
  	// }

    // html,body{
    //   height:100%;
    //   width:100%;
    //   margin:0;
    //   padding:0;

    //  -webkit-transform:  scale(.80,.80 ); 
    // //  -moz-transform:rotate(90deg) scale(.80,.80)
    //   // -moz-transform: scale(.80,.80)
    // }

    // img {
    //   width:100%;
    //   height:100%;
    //   display:block;
	  // }

    @page {
      size: auto;
      width: 11in;
      height:8.5in;
      margin:0;
      padding:.25in;
    }

    #app1Footer {
      visibility:hidden;
    }

    .calendar-window {
      /*visibility: visible;*/
      /*transform: scale(.8) !important;*/
      position: absolute;
      top: 0;
      left:0;
      width:100%;
    }


    .non-printable, #nav, #footer {
      display: none !important;
    }

    .container {
      width: 100%;
      max-width: 100%;
    }

    .col-bg.width-20 {
      max-width: 14%;
      width: 14%;
      flex: 0 0 14%;
      border: 1px solid rgba(0, 0, 0, .5);
    }

    .col-bg.width-20:last-child {
      border-right: 1px solid rgba(0, 0, 0, .5);
    }

    .week-title {
      color: #ddd !important;
      font-size: 12px !important;
      border: 1px solid gray;
      border-bottom: 1px solid rgba(0, 0, 0, .5);
    }

    .week-content .item-detail {
      border-color: rgba(0, 0, 0, .25) !important;
    }

  }


  .logo {
    max-width: 200px;
    float:right;
    padding-right:15px;
  }

  .student-info {
    font-size: 20px;
    text-align: left;

    @media only screen and (max-width: 574px) {
      margin-top: 20px;
    }
  }

  /*#nav {*/
  /*  background:green !important;*/
  /*}*/

  /*.btn-outline-primary {
    color: rgb(31,177,133);
    border-color: rgb(31,177,133);
    min-width: 130px;
  }*/

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:hover,
  .btn-outline-primary:active {
    background-color: rgb(31, 177, 133);
    border-color: rgb(31, 177, 133);
  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 177, 133, 0.5) !important;
  }

  .calendar-title {
    font-size: 3em;
  }

  // See the OrderCalendarCard for info on the day color numbers
  .week-title {
    /*padding-bottom: 10px;*/
    /*padding-top: 10px;*/
    font-weight: 400;
    font-size:1.25em;
    // font-size: 14px;

    &.sunday {
      background: #af0202;
      color: #fff;
    }

    &.monday {
      background: rgb(57, 166, 194);
      color: #fff;
    }

    &.tuesday {
      background: rgb(94, 54, 150);
      color: #fff;
    }

    &.wednesday {
      background: rgb(31, 77, 133);
      color: #fff;
    }

    &.thursday {
      background: rgb(127, 184, 71);
      color: #fff;
    }

    &.friday {
      background: rgb(227, 160, 59);
      color: #fff;
    }

    &.saturday {
      background: #5d3c2e;
    }

    &.none {
      background: #aaa;
    }

  }

  /*.week-title ~ .week-title {*/
  /*  padding-bottom: 10px;*/
  /*  padding-top: 10px;*/
  /*  font-weight: 600;*/
  /*  font-size: 15px;*/
  /*}*/

  .col-bg {
    border:1px solid lightgray;
    &.Sun {
      background: rgba( 175 , 02 , 02 , 0.2 );
    }

    &.Mon {
      background: rgba(57, 166, 194, 0.2);
    }

    &.Tue {
      background: rgba(94, 54, 150, 0.2);
    }

    &.Wed {
      background: rgba(31, 77, 133, 0.2);
    }

    &.Thu {
      background: rgba(127, 184, 71, 0.2);
    }

    &.Fri {
      background: rgba(227, 160, 59, 0.2);
    }

    &.Sat {
      background: rgba( 93 , 60 , 46, 0.2 );
    }

    &.none {
      // background: rgba(77,77,77,0.1 );
    }
  }

  .week-title {
    background: rgba(0, 0, 0, .8);
    color: white;
    text-align: center;
    padding: 2px;
  }

  .week-day-title {
    font-weight: 400;
    font-size:1.75em;

    text-align: left;
    padding: .25em 0 0 .25em;

    // font-size: 14px;

    &.sunday {
      color: #af0202;
      // color: #fff;
    }

    &.monday {
      color: rgb(57, 166, 194);
      // color: #fff;
    }

    &.tuesday {
      color: rgb(94, 54, 150);
      // color: #fff;
    }

    &.wednesday {
      color: rgb(31, 77, 133);
      // color: #fff;
    }

    &.thursday {
      color: rgb(127, 184, 71);
      // color: #fff;
    }

    &.friday {
      color : rgb(227, 160, 59);
      // color: #fff;
    }

    &.saturday {
      color : #5d3c2e;
      // color: #fff;
    }

    &.none {
      // background: #aaa;
    }

  }


  .week-content {
    padding:10px;
    min-height:160px;
    text-align:right;
    font-family: "Tahoma", "Geneva", "sans-serif";

    .item-title {
     
      text-align:right;
      // color:#333;
      font-size: 14px;
      font-weight:600;
    }

    .no-order {
      font-weight: 500;
    }

    .item-detail {
      text-align:right;
    }

    .item-content {
      display: block;
      text-align:right;
      white-space:pre-wrap;
      font-size: 11px;
    }

    .item-description {
      // color:#333;
      font-weight:300;
      font-size:11px;
      white-space:pre-line;
      line-height:14px;
      text-align:right;
      padding-right:1em;
      padding-bottom:.5em;
    }
  }

  .calendar-before {
    background: #eee;
  }

  @media only screen and (min-width: 575px) {
    .width-20 {
      max-width: 14%;
      width: 14%;
      flex: 0 0 14%;
      /*max-width: 20%;*/
      /*width:20%;*/
      /*flex: 0 0 20%;*/
    }
  }

</style>
